/** @ngInject */
export default function config(
	$logProvider,
	$mdThemingProvider,
	$httpProvider,
	$provide,
) {
	// temp disable warnings to prevent aria messages
	// console.warn = function(){};

	/** @ngInject */
	$provide.decorator('$log', function ($delegate) {
		// Save the original $log.warn()
		var warnFn = $delegate.warn

		$delegate.warn = function () {
			if (arguments[0].match && arguments[0].match(/^ARIA:*/)) {
				return true
			}
			// Call the original with the output prepended with formatted timestamp
			warnFn.apply(null, arguments)
		}

		return $delegate
	})

	// Generic error messages
	$httpProvider.interceptors.push('exceptionNotifier')

	// Enable log
	$logProvider.debugEnabled(true)

	var newGrey = $mdThemingProvider.extendPalette('grey', {
		contrastDefaultColor: 'light',
	})

	$mdThemingProvider.definePalette('bond-orange', {
		50: 'FFE2C7',
		100: 'FFCFA2',
		200: 'FFBB7A',
		300: 'FFAC5E',
		400: 'FF9B4D',
		500: 'F68C43',
		600: 'E68233',
		700: 'D77325',
		800: 'D06613',
		900: 'B55000',
		A100: 'FF8A80',
		A200: 'FF5252',
		A400: 'FF1744',
		A700: 'D50000',
		contrastDefaultColor: 'light',
		contrastDarkColors: ['50', '100', '200', '300', '400', 'A100'],
	})

	$mdThemingProvider.definePalette('newGrey', newGrey)
	$mdThemingProvider.theme('navbarTheme').primaryPalette('newGrey', {
		default: '900',
	})

	$mdThemingProvider.theme('error').primaryPalette('red')

	$mdThemingProvider
		.theme('default')
		.primaryPalette('bond-orange')
		.accentPalette('blue-grey')
}
