/** @ngInject */
export default function authService(
	$localStorage,
	$http,
	$rootScope,
	configApiBase,
	$q,
	webNotification,
	User,
) {
	var roles = {
		admin: 'role_admin',
		manager: 'role_manager',
		resourcing: 'role_resourcing',
		user: 'role_user',
	}

	var teams = {
		av: 4,
	}

	var currentUser,
		deferredLogin,
		loggedIn = false

	/*
	 *to ensure deferredLogin promise always exists (for checkAccess)
	 */
	deferredLogin = $q.defer()
	deferredLogin.resolve()

	var service = {
		login: login,
		getToken: getToken,
		getEmail: getEmail,
		logout: logout,
		isLoggedIn: isLoggedIn,
		getCurrentUser: getCurrentUser,
		refreshCurrentUser: refreshCurrentUser,
		getCurrentUserPromise: getCurrentUserPromise,
		reviveSession: reviveSession,
		checkAccess: checkAccess,
		checkTeam: checkTeam,
		roles: roles,
		teams: teams,
		checkAccessPromise: checkAccessPromise,
	}

	function login(email, token) {
		deferredLogin = $q.defer()
		$localStorage.email = email
		$localStorage.token = token
		$http.get(configApiBase + '/me').then(
			function (results) {
				/*
				 * This endpoint should respond with a serialized instance of User.
				 * We inject it into the datastore and assign currentUser to reference the
				 * datastore instance such that future updates to it will be reflected on currentUser
				 */
				const currentUser = results.data
				User.inject(currentUser)
				_setCurrentUser(currentUser)
				deferredLogin.resolve(currentUser)
			},
			function (err) {
				deferredLogin.reject(err)
			},
		)

		return deferredLogin.promise
	}

	function checkAccessPromise(requestedRole) {
		var d = $q.defer()
		deferredLogin.promise.then(
			function () {
				if (checkAccess(requestedRole)) {
					d.resolve(currentUser)
				} else {
					d.reject('unauthorized')
				}
			},
			function () {
				d.reject('unauthorized')
			},
		)
		return d.promise
	}

	function checkAccess(requestedRole) {
		var allowed = false
		if (requestedRole === roles.admin) {
			switch (getCurrentUser().role) {
				case roles.admin:
					allowed = true
					break
			}
		} else if (requestedRole === roles.resourcing) {
			switch (getCurrentUser().role) {
				case roles.resourcing:
				case roles.admin:
					allowed = true
					break
			}
		} else if (requestedRole === roles.manager) {
			switch (getCurrentUser().role) {
				case roles.manager:
				case roles.resourcing:
				case roles.admin:
					allowed = true
					break
			}
		} else if (requestedRole === roles.user) {
			switch (getCurrentUser().role) {
				case roles.user:
				case roles.manager:
				case roles.resourcing:
				case roles.admin:
					allowed = true
					break
			}
		}
		return allowed
	}

	function checkTeam(teamId, userOverride) {
		var user = userOverride || currentUser
		if (!user) return false
		var authorized = false
		angular.forEach(user.teams, function (item, idx) {
			if (item.id === teamId) {
				authorized = true
			}
		})
		return authorized
	}
	function getToken() {
		return $localStorage.token
	}
	function getEmail() {
		return $localStorage.email
	}
	function logout() {
		currentUser = {}
		loggedIn = false
		$http
			.get(configApiBase + '/me/logout')
			.catch(() => false)
			.finally(function () {
				delete $localStorage.email
				delete $localStorage.token
				_setCurrentUser(null)
			})
	}
	function isLoggedIn() {
		if (loggedIn) {
			return true
		} else {
			return false
		}
	}
	function getCurrentUser() {
		return currentUser || {}
	}
	function refreshCurrentUser() {
		var deferred = $q.defer()
		$http.get(configApiBase + '/me').then(
			function (results) {
				_setCurrentUser(results.data)
				deferred.resolve(results)
			},
			function (err) {
				deferred.reject(err)
			},
		)
		return deferred.promise
	}

	function getCurrentUserPromise() {
		return deferredLogin.promise
	}
	function reviveSession() {
		if (getToken() && getEmail()) {
			return login(getEmail(), getToken())
		} else {
			return false
		}
	}

	function _setCurrentUser(userData) {
		if (userData) {
			currentUser = User.inject(userData)
			$rootScope.currentUser = currentUser
			loggedIn = true
		} else {
			currentUser = {}
			loggedIn = false
		}
	}

	return service
}
