/** @ngInject */
export default function config($stateProvider) {
	$stateProvider
		.state('app.users', {
			abstract: true,
			url: '/users',
		})
		.state('app.users.index', {
			url: '',
			component: 'usersIndexState',
			resolve: {
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.admin)
				},
			},
		})
		.state('app.users.index.new', {
			url: '/new',
			/** @ngInject */
			onEnter: function ($stateParams, $state, $document, $mdDialog) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/users/users-new.html',
							controller: 'UsersNewStateController',
							bindToController: true,
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
						})
						.finally(function () {
							$state.go('^')
						})
				})
			},
		})
		.state('app.users.index.show', {
			url: '/:id',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$document,
				$mdDialog,
				$log,
				user,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/users/users-show.html',
							controller: 'UsersShowStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								user: user,
							},
						})
						.then(
							function () {
								$log.info('Closed normally.')
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			/** @ngInject */
			onExit: function ($mdDialog) {
				$mdDialog.hide()
			},
			resolve: {
				/** @ngInject */
				user: function ($stateParams, User) {
					return User.find($stateParams.id).then(function (user) {
						return User.loadRelations(user, [
							'property',
							'department',
							'team',
							'task',
							'project',
						])
					})
				},
			},
		})
		.state('app.users.index.edit', {
			url: '/:id/edit',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$document,
				$mdDialog,
				$log,
				user,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/users/users-edit.html',
							controller: 'UsersEditStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								user: user,
							},
						})
						.then(
							function () {
								$log.info('Closed normally.')
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			/** @ngInject */
			onExit: function ($mdDialog) {
				$mdDialog.hide()
			},
			resolve: {
				/** @ngInject */
				user: function ($stateParams, User) {
					return User.find($stateParams.id, { bypassCache: true })
				},
				/** @ngInject */
				teams: function (Team) {
					return Team.findAll()
				},
				/** @ngInject */
				tasks: function (Task) {
					return Task.findAll()
				},
			},
		})
		.state('app.users.index.delete', {
			url: '/:id/delete',
			/** @ngInject */
			onEnter: function ($stateParams, $state, $mdDialog, $log, User, user) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show(
							$mdDialog.confirm({
								title: 'Are you sure?',
								textContent:
									'Are you sure you want to delete ' + user.name + '?',
								ok: 'Delete',
								cancel: 'Cancel',
								theme: 'md-navbarTheme-theme',
							}),
						)
						.then(
							function () {
								user.DSDestroy().finally(function () {
									$state.go('^')
								})
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			resolve: {
				/** @ngInject */
				user: function ($stateParams, User) {
					return User.find($stateParams.id)
				},
			},
		})
}
