import template from './dashboard.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($state, authService) {
	authService.checkAccessPromise(authService.roles.admin).then(
		function () {
			$state.go('app.reports.hours.week')
		},
		function () {
			authService.checkAccessPromise(authService.roles.manager).then(
				function () {
					$state.go('app.dashboard.user.day')
				},
				function () {
					authService
						.checkAccessPromise(authService.roles.user)
						.then(function () {
							$state.go('app.dashboard.user.day')
						})
				},
			)
		},
	)
}
