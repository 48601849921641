import template from './google-signin-template.html'
/* @ngInject */
export default function GoogleSignIn($window, $q) {
	function load_script() {
		var script = document.createElement('script')
		script.src = 'https://accounts.google.com/gsi/client'
		script.defer = true
		script.async = true
		script.setAttribute('onload', 'window.GISinit()')
		document.getElementsByTagName('head')[0].appendChild(script)
	}

	function lazyLoadApi() {
		var deferred = $q.defer()
		if (!window.google) {
			window.GISinit = function () {
				deferred.resolve()
			}
			if (window.attachEvent) {
				window.attachEvent('onload', load_script)
			} else {
				load_script()
			}
		} else {
			deferred.resolve()
		}
		return deferred.promise
	}

	return {
		restrict: 'E',
		controllerAs: 'vm',
		scope: {},
		controller: GoogleSignInController,
		template: template,
		link: function (scope) {
			lazyLoadApi().then(function () {
				scope.vm.gapiLoad.resolve()
			})
		},
	}
}

/** @ngInject */
function GoogleSignInController(
	$window,
	$mdDialog,
	$q,
	$http,
	$log,
	$sessionStorage,
	configGoogleClientId,
	configApiBase,
	authService,
) {
	var vm = this,
		googleAuth2

	vm.pending = false
	vm.gapiLoad = $q.defer()
	vm.gapiLoad.promise.then(function () {
		vm.GISclient = google.accounts.oauth2.initCodeClient({
			client_id: configGoogleClientId,
			scope: 'profile email',
			ux_mode: 'popup',
			callback: function (response) {
				vm.pending = true
				return fetch(configApiBase + '/auth/google_oauth2/callback', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'X-Requested-With': 'XMLHttpRequest',
					},
					body: 'code=' + response.code,
				})
					.then(function (result) {
						return result.json().then(function (data) {
							var email = data.email
							var token = data.token
							authService.login(email, token)
						})
					})
					.catch(function (error) {
						console.error(error)
						var confirm = $mdDialog
							.confirm()
							.title('Login Error')
							.content(error.data.error)
							.ok('Ok')
						$mdDialog.show(confirm)
					})
					.finally(function () {
						vm.pending = false
					})
			},
		})
	})

	// Begin authentication
	vm.GISsignIn = function () {
		vm.GISclient.requestCode()
	}
}
