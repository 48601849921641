export default function onResize($rootScope, $parse, $window, $timeout) {
	var directive = {
		restrict: 'A',
		link: function ($scope, $element, $attrs) {
			var onResizeGetter = $parse($attrs.onResize)

			$scope.$watch(
				function () {
					return {
						width: $element.outerWidth() || 0,
						height: $element.outerHeight() || 0,
					}
				},
				onResizeCallback,
				true,
			)

			angular.element($window).resize(applyResize)
			$scope.$on('$destroy', function () {
				angular.element($window).off('resize', applyResize)
			})

			$timeout(applyResize, 0)

			function onResizeCallback(size) {
				onResizeGetter($scope, {
					width: size.width || $element.outerWidth(),
					height: size.height || $element.outerHeight(),
				})
			}

			function applyResize() {
				$rootScope.$apply(onResizeCallback)
			}
		},
	}
	return directive
}
