/*
 * js-data expects all model relationships which are set up in DS.defineResource to exist
 * before methods like DSLoadRelations can be called. However, until the factory (e.g. User)
 * is injected into a module, the DS.defineResource method is not called. To overcome this
 * we can use a convention of settings a key (autoInjectAs) on the exported function of
 * the model whose value is the name of the module to be imported in this noop run block.
 *
 * Example:
 * ```
 *   User.autoInjectAs = 'User'
 *   // @ngInject
 *   export default function User(DS, $q, $http, configApiBase) {
 *     ...
 *   }
 * ```
 */
import componentFactories from '../**/*.factory.js'

function injectDSModels(o, $injector) {
	if (typeof o === 'object') {
		Object.values(o).forEach((v) => {
			if (v.default && v.default.autoInjectAs) {
				$injector.get(v.default.autoInjectAs)
			} else injectDSModels(v, $injector)
		})
	}
}

/** @ngInject */
export default function run($injector) {
	injectDSModels(componentFactories, $injector)
}
