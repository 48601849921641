/** @ngInject */
export default function config($stateProvider) {
	$stateProvider
		.state('app.me', {
			abstract: true,
			url: '/me',
		})
		.state('app.me.profile', {
			url: '/profile',
			component: 'meProfileState',
			resolve: {
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.user)
				},
			},
		})
}
