import moment from 'moment'
Version.autoInjectAs = 'Version'

/* @ngInject */
export default function Version(DS) {
	var statuses = {
		active: 'status_active',
		archived: 'status_archived',
	}
	var statusLabels = {
		status_active: 'Active',
		status_archived: 'Archived',
	}

	return DS.defineResource({
		name: 'version',
		endpoint: 'versions',
		computed: {
			created_at_short: ['created_at', createdAtShort],
			status_readable: ['status', readableStatus],
			display_name: ['name', displayName],
		},
		meta: {
			statuses: statuses,
			statusLabels: statusLabels,
		},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					deliverable_id: this.deliverable_id,
					name: this.name,
					number: this.number,
					status: this.status,
				}
			},
		},
		relations: {
			belongsTo: {
				deliverable: [
					{
						localField: 'deliverable',
						localKey: 'deliverable_id',
					},
				],
			},
		},
	})

	function createdAtDate(created_at) {
		return new Date(created_at)
	}

	function createdAtShort(created_at) {
		return moment(created_at).format('MM/DD/YYYY')
	}

	function readableStatus(status) {
		return statusLabels.hasOwnProperty(status) ? statusLabels[status] : ''
	}

	function displayName(name) {
		return name
	}
}

/* @ngInject */
function runBlockVersion(Version) {}
