/*
 * Create templateCache entries for all html files (to be used with templateUrl)
 */
import templates from '../../**/*.html'

function deepTemplates(v, prefix = '', $templateCache) {
	if (typeof v === 'object') {
		Object.entries(v).forEach(([k, v]) => {
			deepTemplates(v, prefix + '/' + k, $templateCache)
		})
	} else if (typeof v === 'string') {
		$templateCache.put(`${prefix}.html`, v)
	} else
		throw new Error(`Cannot process template import ${v} with prefix ${prefix}`)
}

/** @ngInject */
export default function run($templateCache) {
	deepTemplates(templates, 'app', $templateCache)
	$templateCache.put(
		'errors.html',
		`
    <div ng-message="required">Required Field</div>
    <div ng-message="email">Please enter valid email</div>
    `,
	)
}
