import moment from 'moment'
import template from './reports-summary-user.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		user: '<',
		tasks: '<',
		projects: '<',
		users: '<',
	},
}

/* @ngInject */
function controller($scope, $state, $stateParams, $mdToast, Report) {
	var vm = this

	// API
	vm.goThisWeek = goThisWeek
	vm.goLastWeek = goLastWeek
	vm.goThisMonth = goThisMonth
	vm.goLastMonth = goLastMonth
	vm.goThisYear = goThisYear
	vm.goLastYear = goLastYear
	vm.goCustom = goCustom

	vm.$onInit = () => {
		// Scope Attributes
		vm.showCosts = false
		vm.today = moment().hour(0).minute(0).second(0)
		vm.dateStart = (
			$stateParams.date_start ? moment($stateParams.date_start) : moment()
		)
			.hour(0)
			.minute(0)
			.second(0)
		vm.dateStartField = vm.dateStart.toDate()
		vm.dateEnd = (
			$stateParams.date_end
				? moment($stateParams.date_end)
				: moment().add(7, 'days')
		)
			.hour(0)
			.minute(0)
			.second(0)
		vm.dateEndField = vm.dateEnd.toDate()
		vm.title = vm.user.full_name
		vm.boundTasks = vm.tasks
		vm.tasks = {}
		vm.boundProjects = vm.projects
		vm.projects = {}
		vm.chartOptions = {
			animation: {
				duration: 0,
			},
			legend: {
				display: true,
				position: 'left',
				labels: {
					boxWidth: 20,
					fontSize: 10,
				},
			},
		}

		// don't allow entering future date
		if (vm.dateStart.isAfter(vm.today)) {
			$mdToast.show(
				$mdToast
					.simple()
					.textContent('Fortune teller? No going into the future.')
					.position('top right')
					.hideDelay(6000)
					.theme('error'),
			)
			$state.go('.', { date_start: vm.today.format('YYYY-MM-DD') })
		}

		setupAutocomplete()

		angular.forEach(vm.boundTasks, function (item, _idx) {
			vm.tasks[item.id] = item
		})

		angular.forEach(vm.boundProjects, function (item, _idx) {
			vm.projects[item.id] = item
		})

		getAllHours()
		$scope.$watch('vm.showCosts', function () {
			vm.dataField = vm.showCosts ? 'cost' : 'hours'
			chartTaskHours()
			chartJobHours()
		})
	}

	function getAllHours() {
		Report.hours_range_by_job_task({
			params: {
				start_date: vm.dateStart.format('YYYY-MM-DD'),
				end_date: vm.dateEnd.clone().add(1, 'days').format('YYYY-MM-DD'),
				user_id: vm.user.id,
			},
		}).then(function (response) {
			vm.rawTotals = {}
			vm.taskTotals = {}
			vm.projectTotals = {}
			if (response.data.length === 0) {
				vm.noData = true
			} else {
				angular.forEach(response.data, function (item, idx) {
					var qty = parseFloat(item.quantity)
					var cost = parseFloat(item.cost)
					vm.rawTotals[item.project_id] = vm.rawTotals[item.project_id] || {}
					vm.rawTotals[item.project_id][item.task_id] = vm.rawTotals[
						item.project_id
					][item.task_id] || { qty: 0, cost: 0 }
					vm.rawTotals[item.project_id][item.task_id].qty += qty
					vm.rawTotals[item.project_id][item.task_id].cost += cost
					vm.taskTotals[item.task_id] = vm.taskTotals[item.task_id] || {
						qty: 0,
						cost: 0,
					}
					vm.taskTotals[item.task_id].qty += qty
					vm.taskTotals[item.task_id].cost += cost
					vm.projectTotals[item.project_id] = vm.projectTotals[
						item.project_id
					] || { qty: 0, cost: 0 }
					vm.projectTotals[item.project_id].qty += qty
					vm.projectTotals[item.project_id].cost += cost
				})

				chartTaskHours()
				chartJobHours()
				prepSortableHours()
			}
			return response
		})
	}

	function chartTaskHours() {
		vm.taskChart = {}
		vm.taskChart.labels = []
		vm.taskChart.data = []
		vm.taskChart.map = []
		vm.taskChart.totalHours = 0
		vm.taskChart.totalCost = 0
		angular.forEach(vm.taskTotals, function (values, taskId) {
			var n = vm.tasks[taskId].name
			var q = values.qty
			var c = values.cost
			vm.taskChart.map.push({ name: n, hours: q, cost: c })
			vm.taskChart.totalHours += q
			vm.taskChart.totalCost += c
		})
		vm.taskChart.map.sort(function (a, b) {
			if (a[vm.dataField] < b[vm.dataField]) {
				return 1
			} else if (a[vm.dataField] > b[vm.dataField]) {
				return -1
			} else {
				return 0
			}
		})
		angular.forEach(vm.taskChart.map, function (item, idx) {
			// var label = item[vm.dataField] + (item.cost > 0 ? ' ($' + item.cost + ')' : '');
			vm.taskChart.labels.push(item.name)
			vm.taskChart.data.push(item[vm.dataField])
		})
	}

	function chartJobHours() {
		vm.jobChart = {}
		vm.jobChart.labels = []
		vm.jobChart.data = []
		vm.jobChart.map = []
		vm.jobChart.totalHours = 0
		vm.jobChart.totalCost = 0
		angular.forEach(vm.projectTotals, function (values, projectId) {
			var n = vm.projects[projectId].job_code
			var q = values.qty
			var c = values.cost
			vm.jobChart.map.push({ name: n, hours: q, cost: c })
			vm.jobChart.totalHours += q
			vm.jobChart.totalCost += c
		})
		vm.jobChart.map.sort(function (a, b) {
			if (a[vm.dataField] < b[vm.dataField]) {
				return 1
			} else if (a[vm.dataField] > b[vm.dataField]) {
				return -1
			} else {
				return 0
			}
		})
		angular.forEach(vm.jobChart.map, function (item, idx) {
			// var label = item[vm.dataField] + (item.cost > 0 ? ' ($' + item.cost + ')' : '');
			vm.jobChart.labels.push(item.name)
			vm.jobChart.data.push(item[vm.dataField])
		})
	}

	function prepSortableHours() {
		vm.sortableHours = []
		angular.forEach(vm.rawTotals, function (tasks, projectId) {
			var projectRow = {
				project: vm.projects[projectId],
				totalHours: 0,
				totalCost: 0,
				tasks: [],
			}
			angular.forEach(tasks, function (value, taskId) {
				var taskRow = {
					task: vm.tasks[taskId],
					hours: value.qty,
					cost: value.cost,
				}
				projectRow.totalHours += value.qty
				projectRow.totalCost += value.cost
				projectRow.tasks.push(taskRow)
			})
			vm.sortableHours.push(projectRow)
		})
		return vm.sortableHours
	}

	function goTime(start, end) {
		return $state.go('.', {
			user_id: vm.user.id,
			date_start: start.format('YYYY-MM-DD'),
			date_end: end.format('YYYY-MM-DD'),
		})
	}

	function goThisWeek() {
		return goTime(vm.today.clone().day(0), vm.today.clone().day(6))
	}

	function goLastWeek() {
		var start = vm.today.clone().subtract(1, 'weeks')
		return goTime(start.clone().day(0), start.clone().day(6))
	}

	function goThisMonth() {
		var start = vm.today.clone().date(1)
		return goTime(
			start.clone(),
			start.clone().add(1, 'months').subtract(1, 'days'),
		)
	}

	function goLastMonth() {
		var start = vm.today.clone().subtract(1, 'months').date(1)
		return goTime(
			start.clone(),
			start.clone().add(1, 'months').subtract(1, 'days'),
		)
	}

	function goThisYear() {
		var start = vm.today.clone().date(1).month(0)
		return goTime(
			start.clone(),
			start.clone().add(1, 'years').subtract(1, 'days'),
		)
	}

	function goLastYear() {
		var start = vm.today.clone().subtract(1, 'years').date(1).month(0)
		return goTime(
			start.clone(),
			start.clone().add(1, 'years').subtract(1, 'days'),
		)
	}

	function goCustom() {
		vm.user = vm.userAutocomplete.selectedItem
		return goTime(moment(vm.dateStartField), moment(vm.dateEndField))
	}

	function setupAutocomplete() {
		vm.userAutocomplete = {}
		vm.userAutocomplete.selectedItem = vm.user
		vm.userAutocomplete.querySearch = function (query) {
			var results = []
			if (query) {
				results = vm.users.filter(
					(function (query) {
						var lowercaseQuery = angular.lowercase(query)
						return function filterFn(user) {
							return (
								(user.full_name || '').toLowerCase().indexOf(lowercaseQuery) !=
								-1
							)
						}
					})(query),
				)
			} else {
				results = vm.users
			}
			return results
		}
	}
}
