import template from './projects-show.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($mdDialog, $log, project) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel

		//Scope attributes
		vm.project = project

		activate()
		function activate() {}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}
	}
}
