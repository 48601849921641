import template from './versions-edit.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller(
	$scope,
	$state,
	$mdDialog,
	$log,
	Version,
	version,
	Property,
) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.save = save

		//Scope attributes
		vm.title = version.name
		vm.version = version.cloneFields()
		vm.statusOptions = Version.meta.statusLabels

		activate()
		function activate() {
			Property.findAll().then(function (properties) {
				vm.properties = properties
			})
		}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function save() {
			version.DSUpdate(vm.version).then(function (property) {
				close()
			})
		}
	}
}
