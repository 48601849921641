import template from './deliverables-index.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($scope, Deliverable, $state, $stateParams) {
	var vm = this
	vm.$onInit = () => {
		// API
		vm.changeProject = changeProject

		// Scope Attributes
		vm.title = 'Deliverables'
		vm.sortedBy = 'display_name'
		vm.dialog = {}
		vm.activeProject = $stateParams.project_id
		vm.deliverables = []
		vm.tabFilter = 'status_active'

		const deliverablesFilter = { project_id: $stateParams.project_id }
		Deliverable.findAll(deliverablesFilter).then((deliverables) => {
			Deliverable.bindAll(deliverablesFilter, $scope, 'vm.deliverables')
			return deliverables
		})

		function changeProject(to) {
			$state.go('.', { project_id: to })
		}
	}
}
