import _, { lowerCase } from 'lodash'
import template from './projects-edit.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller(
	$scope,
	$filter,
	$mdDialog,
	Project,
	project,
	Property,
	teams,
	users,
	expensesService,
) {
	var vm = this
	vm.$onInit = function () {
		//API methods
		vm.querySearchTeams = querySearchTeams
		vm.projectHasTeam = projectHasTeam
		vm.cancel = cancel
		vm.save = save

		//Scope attributes
		vm.title = project.name
		vm.projectOriginal = project
		vm.project = project.cloneFields()
		vm.statusOptions = Project.meta.statusLabels
		vm.teams = teams
		vm.expensesService = expensesService

		vm.users = users
		vm.dashboard_users = vm.users.filter((m) => {
			return (vm.project.dashboard_user_ids || []).includes(m.id)
		})

		vm.project.teams = project.teams || []
		Property.findAll().then(function (properties) {
			vm.properties = properties
		})
	}

	function cancel() {
		close()
	}

	function close() {
		$mdDialog.cancel()
	}

	function save() {
		project.DSUpdate(vm.project).then(function (project) {
			close()
		})
	}

	function projectHasTeam(team) {
		if (_.find(vm.project.teams, { id: team.id })) return true
		return false
	}

	$scope.$watchCollection('vm.dashboard_users', function (n) {
		if (n)
			vm.project.dashboard_user_ids = n.map(function (u) {
				return u.id
			})
	})

	vm.querySearchUsers = function (query) {
		var results = []
		if (query) {
			results = vm.users.filter(
				(function (query) {
					var lowercaseQuery = lowerCase(query)
					return function filterFn(user) {
						return (
							user.full_name.toLowerCase().indexOf(lowercaseQuery) != -1 &&
							!vm.project.dashboard_user_ids.includes(user.id)
						)
					}
				})(query),
			)
		}
		return results
	}

	function querySearchTeams(query) {
		var results = []
		if (query) {
			results = vm.teams.filter(
				(function (query) {
					var lowercaseQuery = lowerCase(query)
					return function filterFn(item) {
						return (
							item.name.toLowerCase().indexOf(lowercaseQuery) != -1 &&
							!vm.projectHasTeam(item)
						)
					}
				})(query),
			)
		}
		return results
	}
}
