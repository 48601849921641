Report.autoInjectAs = 'Report'
/* @ngInject */
export default function Report(DS) {
	return DS.defineResource({
		name: 'report',
		endpoint: 'reports',
		meta: {},
		actions: {
			budget_usage_by_project: {
				method: 'GET',
			},
			budget_usage_by_day: {
				method: 'GET',
			},
			hours_range_by_user: {
				method: 'GET',
			},
			hours_range_by_job_task: {
				method: 'GET',
			},
			hours_range_by_user_task: {
				method: 'GET',
			},
		},
		methods: {},
		relations: {},
	})
}

/* @ngInject */
function runBlockReport(Report) {}
