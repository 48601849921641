/** @ngInject */
export default function config($stateProvider) {
	$stateProvider
		.state('app.dashboard', {
			abstract: true,
			url: '/dashboard',
		})
		.state('app.dashboard.index', {
			url: '/index',
			component: 'dashboardIndexState',
			resolve: {
				/** @ngInject */
				isAuthorized: async function (authService) {
					return authService.checkAccessPromise(authService.roles.user)
				},
			},
		})
		.state('app.dashboard.user', {
			abstract: true,
			url: '/me',
			resolve: {
				/** @ngInject */
				overtimeRequests: function (OvertimeRequest, authService) {
					return authService
						.getCurrentUserPromise()
						.then(function (currentUser) {
							return OvertimeRequest.findAll({ user_id: currentUser.id })
						})
				},
			},
		})
		.state('app.dashboard.user.day', {
			url: '/day?as_user&date',
			component: 'dayHoursIndexState',
			resolve: {
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.user)
				},
				/** @ngInject */
				asUser: function ($stateParams, User, authService) {
					return authService.getCurrentUserPromise().then(() => {
						if (
							authService.checkAccess(authService.roles.manager) &&
							$stateParams.as_user
						) {
							return User.find($stateParams.as_user)
						} else {
							return authService.getCurrentUser()
						}
					})
				},
			},
		})
		.state('app.dashboard.user.day.new', {
			url: '/new?favorite_id',
			/** @ngInject */
			onEnter: async function (
				$document,
				$state,
				$mdDialog,
				user,
				asUser,
				favorite,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/dashboard/day/day-hours-form.html',
							controller: 'DayHoursFormStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								asUser: asUser,
								user: user,
								hour: null,
								favorite: favorite,
							},
						})
						.finally(function () {
							$state.go('^')
						})
				})
			},
			resolve: {
				/** @ngInject */
				user: async function (asUser) {
					return asUser
				},
				/** @ngInject */
				userTasks: function (user, Task) {
					return Task.findAll({ user_id: user.id })
				},
				favorite: function ($stateParams, Favorite) {
					if ($stateParams.favorite_id) {
						return Favorite.find($stateParams.favorite_id)
					} else {
						return null
					}
				},
			},
		})
		.state('app.dashboard.user.day.edit', {
			url: '/edit/:hour_id',
			/** @ngInject */
			onEnter: async function (
				$stateParams,
				$state,
				$document,
				$mdDialog,
				asUser,
				user,
				hour,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/dashboard/day/day-hours-form.html',
							controller: 'DayHoursFormStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								asUser: asUser,
								user: user,
								hour: hour,
								favorite: null,
							},
						})
						.finally(function () {
							$state.go('^')
						})
				})
			},
			resolve: {
				/** @ngInject */
				user: function (User, Task, asUser) {
					return User.loadRelations(asUser, ['task'])
				},
				/** @ngInject */
				hour: function ($stateParams, Hour) {
					return Hour.find($stateParams.hour_id)
				},
			},
		})
		.state('app.dashboard.user.week', {
			url: '/week?as_user&date',
			component: 'weekHoursIndexState',
			resolve: {
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.user)
				},
				/** @ngInject */
				asUser: function ($stateParams, User, authService) {
					return authService.getCurrentUserPromise().then(function (user) {
						if (
							authService.checkAccess(authService.roles.manager) &&
							$stateParams.as_user
						) {
							return User.find($stateParams.as_user)
						} else {
							return authService.getCurrentUser()
						}
					})
				},
			},
		})
	// .state('app.dashboard.admin', {
	// 	url: '/admin',
	// 	templateUrl: 'app/states/dashboard/dashboard-admin.html',
	// 	controller: 'DashboardAdminStateController as vm',
	// 	resolve: {
	// 		/** @ngInject */
	// 		isAuthorized: function (authService) {
	// 			return authService.checkAccessPromise(authService.roles.admin)
	// 		},
	// 	},
	// })
	// .state('app.dashboard.manager', {
	// 	url: '/manager',
	// 	templateUrl: 'app/states/dashboard/dashboard-manager.html',
	// 	controller: 'DashboardManagerStateController as vm',
	// 	resolve: {
	// 		/** @ngInject */
	// 		isAuthorized: function (authService) {
	// 			return authService.checkAccessPromise(authService.roles.manager)
	// 		},
	// 		/** @ngInject */
	// 		managerRecentProjects: function (authService) {
	// 			return authService.getCurrentUserPromise().then(function (user) {
	// 				return user.getRecentReporteeProjects({})
	// 			})
	// 		},
	// 		/** @ngInject */
	// 		managerReportees: function (authService) {
	// 			return authService.getCurrentUserPromise().then(function (user) {
	// 				return user.getReportees({})
	// 			})
	// 		},
	// 	},
	// })
}
