import moment from 'moment'
import template from './reports-hours-user.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		user: '<',
	},
}

/* @ngInject */
function controller(
	$scope,
	$state,
	$stateParams,
	$filter,
	$mdDialog,
	$mdToast,
	$q,
	authService,
	Hour,

	hoursService,
) {
	var vm = this
	vm.$onInit = function () {
		// API
		vm.hoursService = hoursService
		vm.inDayFilter = inDayFilter
		vm.dayTotalHours = dayTotalHours
		vm.close = close

		// Scope Attributes
		vm.today = moment().hour(0).minute(0).second(0)
		vm.date = ($stateParams.date ? moment($stateParams.date) : moment())
			.hour(0)
			.minute(0)
			.second(0)
		vm.dateLocked = !vm.date.isAfter(vm.user.hours_lock_date)
		vm.weekStart = vm.date.startOf('week')
		vm.datePrevious = vm.date.clone().subtract(1, 'weeks')
		vm.dateNext = vm.date.clone().add(1, 'weeks')
		vm.dateThisWeek = moment().startOf('week')
		vm.isOnThisWeek = vm.dateThisWeek.isSame(vm.weekStart)
		vm.title = vm.user.full_name
		vm.sortedBy = 'project.name'
		vm.hoursSum = 0
		vm.daysOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
		vm.datesOfTheWeek = vm.daysOfTheWeek.map(function (day, idx) {
			return moment(vm.weekStart).day(idx)
		})
		vm.chartOptions = {
			animation: {
				duration: 0,
			},
			legend: {
				display: true,
				position: 'left',
				labels: {
					boxWidth: 20,
					fontSize: 10,
				},
			},
		}

		activate()

		function activate() {
			vm.hoursService
				.getUserWeekHours(vm.user, vm.weekStart)
				.then(function (hours) {
					vm.hours = hours
					vm.hoursSum = 0.0
					angular.forEach(hours, function (hour) {
						vm.hoursSum += parseFloat(hour.quantity) || 0
					})
					vm.taskChart = vm.hoursService.chartTaskHours(hours)
					vm.jobChart = vm.hoursService.chartJobHours(hours)
				})
		}

		function inDayFilter(date) {
			return function (hour) {
				return date.isSame(hour.date, 'day')
			}
		}

		function dayTotalHours(date) {
			var sum = 0
			var hours = Hour.filter({
				user_id: vm.user.id,
				date: date.format('YYYY-MM-DD'),
			})
			angular.forEach(hours, function (hour) {
				sum += parseFloat(hour.quantity)
			})
			return sum
		}

		function close() {
			vm.modal = false
		}
	}
}
