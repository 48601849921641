import moment from 'moment'
Client.autoInjectAs = 'Client'

/* @ngInject */
export default function Client(DS) {
	var statuses = {
		active: 'status_active',
		archived: 'status_archived',
	}
	var statusLabels = {
		status_active: 'Active',
		status_archived: 'Archived',
	}

	return DS.defineResource({
		name: 'client',
		endpoint: 'clients',
		computed: {
			created_at_short: ['created_at', createdAtShort],
			status_readable: ['status', readableStatus],
		},
		meta: {
			statuses: statuses,
			statusLabels: statusLabels,
		},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					organization_id: this.organization_id,
					name: this.name,
					status: this.status,
				}
			},
		},
		relations: {
			hasMany: {
				property: [
					{
						localField: 'properties',
						foreignKey: 'client_id',
					},
				],
			},
		},
	})

	function createdAtDate(created_at) {
		return new Date(created_at)
	}

	function createdAtShort(created_at) {
		return moment(created_at).format('MM/DD/YYYY')
	}

	function readableStatus(status) {
		return statusLabels.hasOwnProperty(status) ? statusLabels[status] : ''
	}
}

/* @ngInject */
function runBlockClient(Client) {}
