import inject from 'inject'
import _, { lowerCase } from 'lodash'
import template from './users-edit.html'

@inject('$mdDialog', '$filter', '$log', 'Task', 'Team', 'Department', 'User')
class controller {
	$onInit() {
		//Scope attributes
		this.statusOptions = this.User.meta.statusLabels
		this.roleOptions = this.User.meta.roleLabels
		this.rateTypeOptions = this.User.meta.rateTypeLabels

		this.userClone = this.user.cloneFields()
		this.title = this.user.full_name

		this.userClone.rate = this.$filter('currency')(this.user.rate)
		this.userClone.teams = this.user.teams || []
		this.userClone.tasks = this.user.tasks || []
		this.userSupervisors = []
		this.user.getSupervisors().then((supervisors) => {
			this.userSupervisors = supervisors
		})
		this.User.findAll().then((users) => {
			this.users = users
		})
		this.Team.findAll().then((teams) => {
			this.teams = teams
		})
		this.Task.findAll().then((tasks) => {
			this.tasks = tasks
		})
		this.Department.findAll().then((departments) => {
			this.departments = departments
		})
	}

	cancel() {
		this.close()
	}

	close() {
		this.$mdDialog.cancel()
	}

	querySearchTasks(query) {
		var results = []
		if (query) {
			results = this.tasks.filter(
				((query) => {
					var lowercaseQuery = lowerCase(query)
					return (item) => {
						return (
							item.name.toLowerCase().indexOf(lowercaseQuery) != -1 &&
							!this.userCloneHasTask(item)
						)
					}
				})(query),
			)
		}
		return results
	}

	querySearchTeams(query) {
		var results = []
		if (query) {
			results = this.teams.filter(
				((query) => {
					var lowercaseQuery = lowerCase(query)
					return (item) => {
						return (
							item.name.toLowerCase().indexOf(lowercaseQuery) != -1 &&
							!this.userCloneHasTeam(item)
						)
					}
				})(query),
			)
		}
		return results
	}

	querySearchSupervisors(query) {
		var results = []
		if (query) {
			results = this.users.filter(
				((query) => {
					var lowercaseQuery = lowerCase(query)
					return (user) => {
						return (
							user.full_name.toLowerCase().indexOf(lowercaseQuery) != -1 &&
							!this.userHasSupervisor(user)
						)
					}
				})(query),
			)
		}
		return results
	}

	userHasTask(item) {
		if (_.find(this.userClone.tasks, { id: item.id })) return true
		return false
	}

	userHasTeam(item) {
		if (_.find(this.userClone.teams, { id: item.id })) return true
		return false
	}

	userHasSupervisor(item) {
		if (_.find(this.userClone.supervisor_ids, { id: item.id })) return true
		return false
	}

	update() {
		if (this.savePending) return
		this.savePending = true
		this.userClone.supervisor_ids = this.userSupervisors.map(
			(supervisor) => supervisor.id,
		)
		this.user
			.DSUpdate(this.userClone)
			.then((_user) => {
				this.close()
			})
			.catch((err) => {
				this.$log.warn(err)
			})
			.finally(() => {
				this.savePending = false
			})
	}
}

export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		user: '<',
	},
}
