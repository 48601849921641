import moment from 'moment'
/** @ngInject */
export default function run($rootScope, $mdToast) {
	//make moment.js available to all views
	$rootScope.moment = moment
	$rootScope.standardDate = 'M/d/yyyy'
	$rootScope.standardDateTime = 'M/d/yyyy @ h:mm a'
	$rootScope.formatDate = function (date) {
		return moment(date).format('MM-DD-YYYY')
	}
	$rootScope.formatDateTime = function (date) {
		return moment(date).format('MM-DD-YYYY HH:ii a')
	}

	$rootScope.$on('httpException', function (event, response) {
		if (response && response.data) {
			var errorOutput = ''
			if (response.data?.status === 'ValidationError') {
				angular.forEach(response.data.field_errors, function (item, idx) {
					if (item && item.length > 0 && item[0]) {
						errorOutput += item[0] + '. '
					}
				})
			} else if (response.data.error === 'Forbidden') {
				errorOutput = 'You are not authorized to perform this action.'
			}

			$mdToast.show(
				$mdToast
					.simple()
					.textContent('Errors: ' + errorOutput)
					.position('top right')
					.hideDelay(6000)
					.theme('error'),
			)
		} else {
			$mdToast.show(
				$mdToast
					.simple()
					.textContent('An unexpected error occurred.')
					.position('top right')
					.hideDelay(6000)
					.theme('error'),
			)
		}
	})
}
