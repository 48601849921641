/** @ngInject */
export default function config(
	DSProvider,
	DSHttpAdapterProvider,
	configApiBase,
) {
	angular.extend(DSProvider.defaults, {})
	angular.extend(DSHttpAdapterProvider.defaults, {
		basePath: configApiBase,
		log: false,
	})
}
