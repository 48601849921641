import inject from 'inject'

@inject('$log', '$scope')
class controller {
	async $onInit() {
		this.apiSignInUrl = process.env.API_SIGN_IN_URL
	}
}

export default {
	template: `
            <md-button
              class="md-raised md-primary"
              ng-href="{{vm.apiSignInUrl}}"
            >
							Sign in with BOND
            </md-button>`,
	controller,
	controllerAs: 'vm',
}
