import _, { lowerCase } from 'lodash'
import template from './teams-new.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		projects: '<',
		users: '<',
	},
}

/* @ngInject */
function controller($scope, $state, $mdDialog, $log, Team, User, Project) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.save = save
		vm.querySearchProjects = querySearchProjects
		vm.querySearchUsers = querySearchUsers
		vm.teamHasProject = teamHasProject
		vm.teamHasUser = teamHasUser

		//Scope attributes
		vm.team = Team.createInstance().cloneFields()
		vm.team.projects ||= []
		vm.team.users ||= []

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function querySearchProjects(query) {
			var results = []
			if (query) {
				results = vm.projects.filter(
					(function (query) {
						var lowercaseQuery = lowerCase(query)
						return function filterFn(item) {
							return (
								((item.name || '').toLowerCase().indexOf(lowercaseQuery) !=
									-1 ||
									(item.job_code || '').toLowerCase().indexOf(lowercaseQuery) !=
										-1 ||
									(item.job_number || '')
										.toString()
										.toLowerCase()
										.indexOf(lowercaseQuery) != -1) &&
								!vm.teamHasProject(item)
							)
						}
					})(query),
				)
			}
			return results
		}

		function querySearchUsers(query) {
			var results = []
			if (query) {
				results = vm.users.filter(
					(function (query) {
						var lowercaseQuery = lowerCase(query)
						return function filterFn(user) {
							return (
								user.full_name.toLowerCase().indexOf(lowercaseQuery) != -1 &&
								!vm.teamHasUser(user)
							)
						}
					})(query),
				)
			}
			return results
		}

		function teamHasProject(project) {
			if (_.find(vm.team.projects, { id: project.id })) return true
			return false
		}

		function teamHasUser(user) {
			if (_.find(vm.team.users, { id: user.id })) return true
			return false
		}

		function save() {
			Team.create(vm.team).then(function (team) {
				close()
			})
		}
	}
}
