import moment from 'moment'
Expense.autoInjectAs = 'Expense'

/* @ngInject */
export default function Expense(DS, Project) {
	//status
	var statuses = {
		active: 'status_active',
		deleted: 'status_deleted',
	}
	var statusLabels = {
		status_active: 'Active',
		status_deleted: 'Deleted',
	}
	var expensableModels = [Project]

	// Without this "glue" relation definition, the below belongsTo for expensables
	// won't work because js-data errors out without the field key representing a
	// "real" resource. This is a representation of the polymorphic parent (expensable)
	// of any expense
	DS.defineResource({ name: 'expensable' })

	return DS.defineResource({
		name: 'expense',
		endpoint: 'expenses',
		computed: {
			created_at_short: ['created_at', createdAtShort],
			status_readable: ['status', readableStatus],
		},
		meta: {
			statuses: statuses,
			statusLabels: statusLabels,
			expensableModels: expensableModels,
		},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					status: this.status,
					amount: this.amount,
					memo: this.memo,
					expensable_id: this.expensable_id,
					expensable_type: this.expensable_type,
				}
			},
		},
		relations: {
			belongsTo: {
				expensable: {
					localField: 'expensable',
					get: function (_Expense, _relationDef, expense, _origGetter) {
						return DS.get(
							_resourceNameFromClass(expense.expensable_type),
							expense.expensable_id,
						)
					},
					load: function (_Expense, _relationDef, expense, _options) {
						return DS.find(
							_resourceNameFromClass(expense.expensable_type),
							expense.expensable_id,
						)
					},
				},
			},
		},
	})

	function createdAtDate(created_at) {
		return new Date(created_at)
	}

	function createdAtShort(created_at) {
		return moment(created_at).format('MM/DD/YYYY')
	}

	function readableStatus(status) {
		return statusLabels.hasOwnProperty(status) ? statusLabels[status] : ''
	}

	function _resourceNameFromClass(klass) {
		/*
		 * Convert the class name of a resource (eg: Project, OvertimeRequest) into the
		 * resource name as referenced by DS (eg: project, overtimeRequest) due to our convention
		 */
		return klass.charAt(0).toLowerCase() + klass.slice(1)
	}
}

/* @ngInject */
function runBlockExpense(Expense) {}
