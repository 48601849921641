import template from './tasks-new.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($scope, $state, $mdDialog, $log, Task) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.save = save

		//Scope attributes
		vm.task = Task.createInstance()
		vm.statusOptions = Task.meta.statusLabels

		activate()
		function activate() {
			vm.task.status = Task.meta.statuses.active
		}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function save() {
			Task.create(vm.task).then(function (task) {
				close()
			})
		}
	}
}
