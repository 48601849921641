import { RejectType } from '@uirouter/angularjs'

/** @ngInject */
export default function runBlock(
	$state,
	$rootScope,
	$transitions,
	transitionCodes,
	$mdToast,
	authService,
	$timeout,
	$log,
) {
	$transitions.onError({ to: 'app.**' }, (transition) => {
		$rootScope.stateChangePending = false
		const targetState = transition.targetState()
		if (!authService.isLoggedIn()) {
			const state = targetState.name()
			const params = targetState.params()

			// Preserve and redirect to intended state (unless it's home, logout, or an error)
			const goParams =
				['app.logout', 'app.error'].indexOf(state) > -1
					? {}
					: {
							targetState: {
								state,
								params,
							},
						}

			return $state.go('guest.login', goParams)
		}

		const error = transition.error() || {}
		$log.error('transition error', error)
		// ignore transition error if transition is intentionally redirected
		if (error.redirected || error.type === RejectType.IGNORED) return

		/*
		 * Use location: false to prevent users needing to press
		 * back button twice to return to the last valid state
		 */
		if (error.detail === transitionCodes.CANCELED) {
			$log.info('cancelled transition')
			return
		} else if (error.type == 2) {
			$log.info('superseded transition')
			return
		} else if (error.detail === 'unauthorized') {
			$log.error('unauth error')
			$mdToast.show(
				$mdToast
					.simple()
					.textContent("You're not authorized to do that.")
					.position('top right')
					.hideDelay(6000)
					.theme('error'),
			)
		} else {
			$log.info('route unknown error')
			$state.go('app.error', { error, targetState }, { location: false })
		}
	})

	$transitions.onStart(null, (transition) => {
		const error = transition.error()
		if (error) $log.warn(error)
		$rootScope.stateChangePending = true

		const isModal = Object.keys(transition.to()?.views || {}).some((viewName) =>
			/^modal/.test(viewName),
		)
		$rootScope.modalState = isModal
	})

	$transitions.onSuccess(null, (transition) => {
		const error = transition.error()
		if (error) $log.warn(error)

		$rootScope.stateChangePending = false

		$timeout(function () {
			$rootScope.$emit('debounce:lazyImg:refresh')
		}, 0)
	})
}
