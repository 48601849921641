import template from './properties-new.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($scope, $state, $mdDialog, $log, Property, Client) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.save = save

		//Scope attributes
		vm.property = Property.createInstance()
		vm.statusOptions = Property.meta.statusLabels

		activate()
		function activate() {
			vm.property.status = Property.meta.statuses.active
			Client.findAll().then(function (clients) {
				vm.clients = clients
			})
		}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function save() {
			Property.create(vm.property).then(function (property) {
				close()
			})
		}
	}
}
