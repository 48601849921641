import modalTemplate from './modal.html'
export default function modal() {
	var directive = {
		restrict: 'E',
		controller: modalController,
		controllerAs: 'vm',
		scope: {
			title: '@',
			shown: '=?',
			onHide: '&',
			whenShown: '&',
			whenHidden: '&',
			noHide: '=?',
			bgClass: '@',
			options: '=?',
		},
		template: modalTemplate,
		transclude: true,
	}
	return directive
}

/** @ngInject */
function modalController($scope, $document, $element, $timeout, $q) {
	/*jshint validthis:true*/
	var vm = this

	// API
	vm.close = close
	vm.handleKeydown = handleKeydown

	// Scope Attributes

	vm.$onInit = function () {
		if (angular.isUndefined($scope.shown)) {
			$scope.shown = true
		}

		var defaultOptions = {
				widthClass: 'w-1/2',
				toolbar: true,
				backgroundColor: 'white',
			},
			detach = function () {
				$element.detach()
			},
			reattach = function () {
				detach()
				angular.element($document[0].body).append($element)
			}

		$scope.$watchCollection('options', function (options) {
			vm.options = Object.assign({}, defaultOptions, options)
		})
		detach()
		$scope.$watch('shown', function (shown, wasShown) {
			if (shown) {
				reattach()
			}
			if (shown !== wasShown) {
				if (shown) {
					$scope.whenShown()
				} else {
					$scope.whenHidden()
				}
			}
		})

		var keydownHandler = vm.handleKeydown.bind(vm)

		$scope.$on('$destroy', function () {
			$document.off('keydown', keydownHandler)
			$element.remove()
		})

		$document.on('keydown', keydownHandler)
	}

	function close() {
		if (!$scope.shown) return
		if ($scope.onHide) {
			var result = $scope.onHide()
			if (!result || !result.then) {
				result = $q.resolve()
			}
			result.then(function (res) {
				$scope.shown = false
				return res
			})
		}
	}

	function handleKeydown(evt) {
		if (evt.which === 27) {
			vm.close()
		}
	}
}
