import inject from 'inject'
import template from './reports-summary-project-edit.html'
import { pick } from 'lodash'

/* @ngInject */
@inject('$scope', '$state', '$mdDialog', 'Project')
class controller {
	$onInit() {
		this.projectClone = pick(this.project.cloneFields(), [
			'budget',
			'production_start_date',
			'production_end_date',
		])
	}

	save() {
		this.project.DSUpdate(this.projectClone).then(() => this.cancel())
	}

	cancel() {
		this.$mdDialog.cancel()
	}
}

export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		project: '<',
	},
}
