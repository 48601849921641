import _ from 'lodash'
import template from './departments-new.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller(
	$scope,
	$state,
	$mdDialog,
	$log,

	Department,
	User,
	Project,
) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.save = save

		//Scope attributes
		vm.department = Department.createInstance().cloneFields()

		activate()
		function activate() {}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function save() {
			Department.create(vm.department).then(function (department) {
				close()
			})
		}
	}
}
