import moment from 'moment'
Favorite.autoInjectAs = 'Favorite'

/* @ngInject */
export default function Favorite(DS) {
	return DS.defineResource({
		name: 'favorite',
		endpoint: 'favorites',
		computed: {
			created_at_short: ['created_at', createdAtShort],
		},
		meta: {},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					user_id: this.user_id,
					task_id: this.task_id,
					version_id: this.version_id,
					deliverable_id: this.deliverable_id,
					project_id: this.project_id,
				}
			},
		},
		relations: {
			belongsTo: {
				user: [
					{
						localField: 'user',
						localKey: 'user_id',
					},
				],
				task: [
					{
						localField: 'task',
						localKey: 'task_id',
					},
				],
				version: [
					{
						localField: 'version',
						localKey: 'version_id',
					},
				],
				deliverable: [
					{
						localField: 'deliverable',
						localKey: 'deliverable_id',
					},
				],
				project: [
					{
						localField: 'project',
						localKey: 'project_id',
					},
				],
			},
		},
	})

	function createdAtDate(created_at) {
		return new Date(created_at)
	}

	function createdAtShort(created_at) {
		return moment(created_at).format('MM/DD/YYYY')
	}
}

/* @ngInject */
function runBlockFavorite(Favorite) {}
