import template from './properties-index.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($scope, $log, Property) {
	var vm = this
	vm.$onInit = () => {
		//API methods

		//Scope Attributes
		vm.title = 'Properties'
		vm.tabFilter = 'status_active'
		vm.sortedBy = 'name_sortable'
		vm.properties = []

		Property.findAll().then((properties) => {
			Property.bindAll({}, $scope, 'vm.properties')
			return properties
		})
	}
}
