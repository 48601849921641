import moment from 'moment'
Task.autoInjectAs = 'Task'

/* @ngInject */
export default function Task(DS) {
	var statuses = {
		active: 'status_active',
		archived: 'status_archived',
	}
	var statusLabels = {
		status_active: 'Active',
		status_archived: 'Archived',
	}

	return DS.defineResource({
		name: 'task',
		endpoint: 'tasks',
		computed: {
			created_at_short: ['created_at', createdAtShort],
			status_readable: ['status', readableStatus],
			display_name: ['name', displayName],
		},
		meta: {
			statuses: statuses,
			statusLabels: statusLabels,
		},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					organization_id: this.organization_id,
					name: this.name,
					status: this.status,
				}
			},
		},
		relations: {
			hasMany: {
				property: {
					localField: 'properties',
					foreignKey: 'task_id',
				},
				user: {
					localField: 'users',
					foreignKeys: 'user_ids',
				},
			},
		},
	})

	function createdAtDate(created_at) {
		return new Date(created_at)
	}

	function createdAtShort(created_at) {
		return moment(created_at).format('MM/DD/YYYY')
	}

	function readableStatus(status) {
		return statusLabels.hasOwnProperty(status) ? statusLabels[status] : ''
	}

	function displayName(name) {
		return name
	}
}

/* @ngInject */
function runBlockTask(Task) {}
