import template from './dashboard-manager.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller(managerRecentProjects) {
	var vm = this
	vm.$onInit = () => {
		// API

		// Scope attributes
		vm.title = 'Dashboard'
		vm.managerRecentProjects = managerRecentProjects

		activate()
		function activate() {}
	}
}
