import * as pathToImages from '../../../assets/img/**/*'

function flattenPaths(src, target = {}, prefix = '') {
	for (const k of Object.keys(src)) {
		if (typeof src[k] === 'object') {
			flattenPaths(src[k], target, prefix + k + '/')
		} else {
			target[prefix + k] = src[k]
		}
	}

	return target
}
const flatPaths = flattenPaths(pathToImages)

/** @ngInject */
const imageFilter = ($log) => (value) => {
	let output = ''
	try {
		output = flatPaths[value]
		if (!output) throw Error(`no image exists: ${value}`)
	} catch (e) {
		output = ''
		$log.error(e)
	}
	return output
}

export default imageFilter
