/** @ngInject */
export default function config($stateProvider) {
	$stateProvider
		.state('app.projects', {
			abstract: true,
			url: '/projects',
		})
		.state('app.projects.index', {
			url: '?client_id',
			component: 'projectsIndexState',
		})
		.state('app.projects.index.new', {
			url: '/new',
			resolve: {
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.admin)
				},
			},
			/** @ngInject */
			onEnter: function ($stateParams, $state, $document, $mdDialog, User) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							resolve: {
								/** @ngInject */
								teams: function (Team) {
									return Team.findAll()
								},
								/** @ngInject */
								users: function (User) {
									return User.findAll()
								},
							},
							templateUrl: 'app/states/projects/projects-new.html',
							controller: 'ProjectsNewStateController',
							bindToController: true,
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
						})
						.finally(function () {
							$state.go('^')
						})
				})
			},
		})
		.state('app.projects.index.edit', {
			url: '/edit/:id',
			resolve: {
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.admin)
				},
			},
			/** @ngInject */
			onEnter: function (Project, $stateParams, $state, $document, $mdDialog) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							resolve: {
								/** @ngInject */
								project: function () {
									return Project.find($stateParams.id)
								},
								/** @ngInject */
								teams: function (Team) {
									return Team.findAll()
								},
								/** @ngInject */
								users: function (User) {
									return User.findAll()
								},
							},
							templateUrl: 'app/states/projects/projects-edit.html',
							controller: 'ProjectsEditStateController',
							bindToController: true,
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
						})
						.finally(function () {
							$state.go('^')
						})
				})
			},
		})
		.state('app.projects.index.show', {
			url: '/:id',
			component: 'projectsShowState',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$document,
				$mdDialog,
				$log,
				project,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/projects/projects-show.html',
							controller: 'ProjectsShowStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								project: project,
							},
						})
						.then(
							function () {
								$log.info('Closed normally.')
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			/** @ngInject */
			onExit: function ($mdDialog) {
				$mdDialog.hide()
			},
			resolve: {
				/** @ngInject */
				project: function ($stateParams, Project) {
					return Project.find($stateParams.id).then(function (project) {
						return Project.loadRelations(project, ['property', 'deliverable'])
					})
				},
			},
		})
		.state('app.projects.index.delete', {
			url: '/:id/delete',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$mdDialog,
				$log,
				Project,
				project,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show(
							$mdDialog.confirm({
								title: 'Are you sure?',
								textContent:
									'Are you sure you want to delete ' + project.name + '?',
								ok: 'Delete',
								cancel: 'Cancel',
								theme: 'md-navbarTheme-theme',
							}),
						)
						.then(
							function () {
								project.DSDestroy().finally(function () {
									$state.go('^')
								})
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			resolve: {
				/** @ngInject */
				project: function ($stateParams, Project) {
					return Project.find($stateParams.id)
				},
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.admin)
				},
			},
		})
}
