import moment from 'moment'
Department.autoInjectAs = 'Department'

/* @ngInject */
export default function Department(DS) {
	return DS.defineResource({
		name: 'department',
		endpoint: 'departments',
		computed: {
			created_at_short: ['created_at', createdAtShort],
		},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					name: this.name,
					users: this.users,
				}
			},
		},
		relations: {
			hasMany: {
				user: [
					{
						localField: 'users',
						foreignKey: 'user_id',
					},
				],
			},
		},
	})

	function createdAtDate(created_at) {
		return new Date(created_at)
	}

	function createdAtShort(created_at) {
		return moment(created_at).format('MM/DD/YYYY')
	}
}

/* @ngInject */
function runBlockDepartment(Department) {}
