import layoutBase from '../layouts/base.html'

/** @ngInject */
export default function config(
	$stateProvider,
	$urlRouterProvider,
	$urlMatcherFactoryProvider,
) {
	$urlMatcherFactoryProvider.strictMode(false)

	$stateProvider.state('app', {
		abstract: true,
		url: '',
		views: {
			base: {
				template: layoutBase,
				controller: 'AppController as appController',
			},
		},
		resolve: {
			/** @ngInject */
			isAuthorized: function (authService) {
				return authService.checkAccessPromise(authService.roles.user)
			},
			/** @ngInject */
			avTeam: async function (Team, authService) {
				// Users may be checked (whether they are in AV team) to conditionally render UI
				// specifically: authService.checkTeam
				return Team.find(authService.teams.av)
			},
		},
	})

	$urlRouterProvider.otherwise('/')
}
