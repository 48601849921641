import propertiesNewTemplate from '../properties/properties-new.html'
import propertiesShowTemplate from '../properties/properties-show.html'
import propertiesEditTemplate from '../properties/properties-edit.html'

/** @ngInject */
export default function config($stateProvider) {
	$stateProvider
		.state('app.properties', {
			abstract: true,
			url: '/properties',
		})
		.state('app.properties.index', {
			url: '',
			component: 'propertiesIndexState',
			resolve: {
				clients: function (Client) {
					return Client.findAll()
				},
			},
		})
		.state('app.properties.index.new', {
			url: '/new',
			/** @ngInject */
			onEnter: function ($stateParams, $state, $document, $mdDialog) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							template: propertiesNewTemplate,
							controller: 'PropertiesNewStateController',
							bindToController: true,
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
						})
						.finally(function () {
							$state.go('^')
						})
				})
			},
			resolve: {
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.admin)
				},
			},
		})
		.state('app.properties.index.show', {
			url: '/:id',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$document,
				$mdDialog,
				$log,
				property,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							template: propertiesShowTemplate,
							controller: 'PropertiesShowStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								property: property,
							},
						})
						.then(
							function () {
								$log.info('Closed normally.')
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			/** @ngInject */
			onExit: function ($mdDialog) {
				$mdDialog.hide()
			},
			resolve: {
				/** @ngInject */
				property: function ($stateParams, Property) {
					return Property.find($stateParams.id).then(function (property) {
						return Property.loadRelations(property, ['client', 'project'])
					})
				},
			},
		})
		.state('app.properties.index.edit', {
			url: '/:id/edit',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$document,
				$mdDialog,
				$log,
				property,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							template: propertiesEditTemplate,
							controller: 'PropertiesEditStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								property: property,
							},
						})
						.then(
							function () {
								$log.info('Closed normally.')
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			/** @ngInject */
			onExit: function ($mdDialog) {
				$mdDialog.hide()
			},
			resolve: {
				/** @ngInject */
				property: function ($stateParams, Property) {
					return Property.find($stateParams.id, { bypassCache: true })
				},
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.admin)
				},
			},
		})
		.state('app.properties.index.delete', {
			url: '/:id/delete',
			onEnter: function (
				$stateParams,
				$state,
				$mdDialog,
				$log,
				Property,
				property,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show(
							$mdDialog.confirm({
								title: 'Are you sure?',
								textContent:
									'Are you sure you want to delete ' + property.name + '?',
								ok: 'Delete',
								cancel: 'Cancel',
								theme: 'md-navbarTheme-theme',
							}),
						)
						.then(
							function () {
								property.DSDestroy().finally(function () {
									$state.go('^')
								})
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			resolve: {
				/** @ngInject */
				property: function ($stateParams, Property) {
					return Property.find($stateParams.id)
				},
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.admin)
				},
			},
		})
}
