import { clone as _clone } from 'lodash'
import moment from 'moment'
User.autoInjectAs = 'User'

/* @ngInject */
export default function User(DS, $q, $http, configApiBase) {
	//status
	var statuses = {
		active: 'status_active',
		pending: 'status_pending',
		archived: 'status_archived',
	}
	var statusLabels = {
		status_active: 'Active',
		status_pending: 'Pending',
		status_archived: 'Archived',
	}

	//roles
	var roles = {
		user: 'role_user',
		manager: 'role_manager',
		resourcing: 'role_resourcing',
		admin: 'role_admin',
	}
	var roleLabels = {
		role_user: 'User',
		role_manager: 'Manager',
		role_resourcing: 'Resourcing',
		role_admin: 'Admin',
	}

	//rate types
	var rateTypes = {
		hourly: 'rate_type_hourly',
		daily: 'rate_type_daily',
	}
	var rateTypeLabels = {
		rate_type_hourly: 'Hourly',
		rate_type_daily: 'Daily',
	}

	return DS.defineResource({
		name: 'user',
		endpoint: 'users',
		computed: {
			created_at_short: ['created_at', createdAtShort],
			status_readable: ['status', readableStatus],
			role_readable: ['role', readableRole],
			rate_type_readable: ['rate_type', readableRateType],
			needs_review: [
				'requires_timesheet_approval',
				'hours_lock_date',
				'hours_approved_date',
				needsReview,
			],
		},
		meta: {
			statuses: statuses,
			statusLabels: statusLabels,
			roles: roles,
			roleLabels: roleLabels,
			rateTypes: rateTypes,
			rateTypeLabels: rateTypeLabels,
		},
		relations: {
			belongsTo: {
				department: [
					{
						localField: 'department',
						localKey: 'department_id',
					},
				],
			},
			hasMany: {
				user: {
					localField: 'reportees',
					localKeys: 'reportee_ids',
				},
				comp: {
					localField: 'comps',
					foreignKey: 'user_id',
				},
				project: {
					localField: 'projects',
					foreignKey: 'user_id',
				},
				team: {
					localField: 'teams',
					localKeys: 'team_ids',
				},
				task: {
					localField: 'tasks',
					localKeys: 'task_ids',
				},
				overtimeRequest: [
					{
						localField: 'overtime_requests',
						foreignKey: 'user_id',
					},
					{
						localField: 'audited_overtime_requests',
						foreignKey: 'audited_by_id',
					},
				],
			},
		},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					first_name: this.first_name,
					last_name: this.last_name,
					full_name: this.full_name,
					legal_name: this.legal_name,
					employee_id: this.employee_id,
					department_id: this.department_id,
					status: this.status,
					email: this.email,
					role: this.role,
					rate: this.rate,
					rate_type: this.rate_type,
					remindable: this.remindable,
					requires_timesheet_approval: this.requires_timesheet_approval,
					track_overtime: this.track_overtime,
					is_part_time: this.is_part_time,
					sick_hours_per_year: this.sick_hours_per_year,
					image_url: this.image_url,
					task_ids: _clone(this.task_ids),
					team_ids: _clone(this.team_ids),
					reportee_ids: _clone(this.reportee_ids),
					supervisor_ids: _clone(this.supervisor_ids),
				}
			},
			getReportees: function () {
				return $q.all(
					this.reportee_ids.map(function (reportee_id) {
						return DS.find('user', reportee_id)
					}),
				)
			},
			getSupervisors: function () {
				return $q.all(
					this.supervisor_ids.map(function (supervisor_id) {
						return DS.find('user', supervisor_id)
					}),
				)
			},
			getRecentReporteeProjects: function (params) {
				params = params || {}
				params.user_id = this.id
				return $http
					.get(configApiBase + '/users/recent_reportee_projects', {
						params: params,
					})
					.then(function (resp) {
						return DS.inject('project', resp.data)
					})
			},
		},
	})

	function createdAtDate(created_at) {
		return new Date(created_at)
	}

	function createdAtShort(created_at) {
		return moment(created_at).format('MM/DD/YYYY')
	}

	function readableStatus(status) {
		return statusLabels.hasOwnProperty(status) ? statusLabels[status] : ''
	}

	function readableRole(role) {
		return roleLabels.hasOwnProperty(role) ? roleLabels[role] : ''
	}

	function readableRateType(rateType) {
		return rateTypeLabels.hasOwnProperty(rateType)
			? rateTypeLabels[rateType]
			: ''
	}

	function needsReview(
		requires_timesheet_approval,
		hours_lock_date,
		hours_approved_date,
	) {
		return requires_timesheet_approval && hours_lock_date != hours_approved_date
	}
}

/* @ngInject */
function runBlockUser(User) {}
