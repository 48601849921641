import _, { lowerCase } from 'lodash'
import template from './teams-edit.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		team: '<',
		users: '<',
		projects: '<',
	},
}

/* @ngInject */
function controller($mdDialog, $log, Project, Team, User) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.update = update
		vm.querySearchProjects = querySearchProjects
		vm.querySearchUsers = querySearchUsers
		vm.teamHasProject = teamHasProject
		vm.teamHasUser = teamHasUser

		//Scope attributes
		vm.title = vm.team.name
		vm.clonedTeam = vm.team.cloneFields()

		vm.clonedTeam.users = vm.team.users || []
		vm.clonedTeam.projects = vm.team.projects || []

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function querySearchProjects(query) {
			var results = []
			if (query) {
				results = vm.projects.filter(
					(function (query) {
						var lowercaseQuery = lowerCase(query)
						return function filterFn(item) {
							return (
								((item.name || '').toLowerCase().indexOf(lowercaseQuery) !=
									-1 ||
									(item.job_code || '').toLowerCase().indexOf(lowercaseQuery) !=
										-1 ||
									(item.job_number || '')
										.toString()
										.toLowerCase()
										.indexOf(lowercaseQuery) != -1) &&
								!vm.teamHasProject(item)
							)
						}
					})(query),
				)
			}
			return results
		}

		function querySearchUsers(query) {
			var results = []
			if (query) {
				results = vm.users.filter(
					(function (query) {
						var lowercaseQuery = lowerCase(query)
						return function filterFn(user) {
							return (
								user.full_name.toLowerCase().indexOf(lowercaseQuery) != -1 &&
								!vm.teamHasUser(user)
							)
						}
					})(query),
				)
			}
			return results
		}

		function teamHasProject(project) {
			if (_.find(vm.clonedTeam.projects, { id: project.id })) return true
			return false
		}

		function teamHasUser(user) {
			if (_.find(vm.clonedTeam.users, { id: user.id })) return true
			return false
		}

		function update() {
			vm.team.DSUpdate(vm.clonedTeam).then(
				function (team) {
					close()
				},
				function (err) {
					$log.warn(err)
				},
			)
		}
	}
}
