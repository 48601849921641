/** @ngInject */
export default function overtimeRequestsService(OvertimeRequest, authService) {
	var service = {
		create: create,
		userRequestsPending: userRequestsPending,
	}

	function create(overtime_date, memo, cb) {
		OvertimeRequest.create({
			user_id: authService.getCurrentUser().id,
			overtime_date: overtime_date,
			memo: memo,
		})
			.then(function (otr) {
				OvertimeRequest.inject(otr)
			})
			.finally(function () {
				if (typeof cb == 'function') cb()
			})
	}

	function userRequestsPending(user) {
		user = user || authService.getCurrentUser()
		return !!OvertimeRequest.filter({
			user_id: user.id,
			status: OvertimeRequest.meta.statuses.pending,
		}).length
	}

	return service
}
