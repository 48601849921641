import template from './tasks-index.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		tasks: '<',
	},
}

/* @ngInject */
function controller($scope, $log, Task) {
	var vm = this
	vm.$onInit = () => {
		//API methods

		//Scope Attributes
		vm.title = 'Tasks'
		vm.tabFilter = 'status_active'
		vm.sortedBy = 'name'
		vm.tasks = []

		Task.findAll().then((tasks) => {
			Task.bindAll({}, $scope, 'vm.tasks')
			return tasks
		})
	}
}
