import _union from 'lodash/union'

export default (...$inject) =>
	(target) => {
		$inject = _union($inject, target.$inject || [])
		const result = class extends target {
			constructor() {
				const args = arguments
				super(...args)
				const _this = this
				$inject.forEach(($inject, i) => {
					_this[$inject] = args[i]
				})
				this.init()
			}

			init() {
				super.init && super.init()
			}
		}
		result.$inject = $inject
		return result
	}
