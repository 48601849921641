/** @ngInject */
export default function config($stateProvider) {
	$stateProvider
		.state('app.reports', {
			abstract: true,
			url: '/reports',
		})
		.state('app.reports.hours', {
			abstract: true,
			url: '/hours',
			resolve: {
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.manager)
				},
				/** @ngInject */
				overtimeRequests: function (OvertimeRequest, authService) {
					return authService
						.getCurrentUserPromise()
						.then(function (currentUser) {
							return OvertimeRequest.findAll({}, { bypassCache: true })
						})
				},
			},
		})
		.state('app.reports.hours.week', {
			url: '/week?date',
			component: 'reportsHoursWeekState',
			resolve: {
				/** @ngInject */
				users: async function (User, authService) {
					const viewAllUsers = !!(await authService
						.checkAccessPromise(authService.roles.resourcing)
						.catch(() => false))
					return (
						await User.findAll(
							viewAllUsers ? {} : { permitted_users_only: true },
						)
					).filter((user) => user.status == User.meta.statuses.active)
				},
			},
		})
		.state('app.reports.hours.user', {
			url: '/user?user_id?date',
			component: 'reportsHoursUserState',
			resolve: {
				/** @ngInject */
				user: function (User, $stateParams) {
					return User.find($stateParams.user_id)
				},
			},
		})
		.state('app.reports.hours.user.overtimeRequestAudit', {
			url: '/ot?otr_id?auto_approve?disapprove',
			views: {
				'modal@app': {
					component: 'overtimeRequestAuditState',
				},
			},
			resolve: {
				/** @ngInject */
				otr: function (OvertimeRequest, $stateParams) {
					return OvertimeRequest.find($stateParams.otr_id)
				},
			},
		})
		.state('app.reports.hours.user.hoursAudit', {
			url: '/hours_audit?audit_date',
			views: {
				'modal@app': {
					component: 'hoursAuditState',
				},
			},
			resolve: {
				/** @ngInject */
				approvalDate: function ($stateParams, $q) {
					var d = $q.defer()
					if (!$stateParams.audit_date)
						d.reject(new Error('Missing audit_date param'))
					else d.resolve()
					return d.promise
				},
			},
		})
		.state('app.reports.summary', {
			abstract: true,
			url: '/summary',
		})
		.state('app.reports.summary.user', {
			url: '/user?user_id?date_start?date_end',
			component: 'reportsSummaryUserState',
			resolve: {
				/** @ngInject */
				user: function (User, $stateParams) {
					return User.find($stateParams.user_id)
				},
				tasks: function (Task) {
					return Task.findAll()
				},
				projects: function (Project) {
					return Project.findAll({ status: Project.meta.statuses.active })
				},
				users: function (User) {
					return User.findAll()
				},
			},
		})
		.state('app.reports.summary.project', {
			abstract: true,
			url: '/project',
		})
		.state('app.reports.summary.project.index', {
			url: '',
			component: 'reportsSummaryProjectIndexState',
			resolve: {
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.manager)
				},
				projects: function (Project) {
					// Prime the data store with all active projects so that we can filter against them in the controller
					return Project.findAll({ status: Project.meta.statuses.active })
				},
			},
		})
		.state('app.reports.summary.project.view', {
			url: '/:project_id?date_start?date_end',
			component: 'reportsSummaryProjectViewState',
			resolve: {
				/** @ngInject */
				project: function (Project, $stateParams) {
					return Project.find($stateParams.project_id)
				},
				/** @ngInject */
				tasks: function (Task) {
					return Task.findAll()
				},
				/** @ngInject */
				projects: function (Project) {
					return Project.findAll({ status: Project.meta.statuses.active })
				},
				/** @ngInject */
				users: function (User) {
					return User.findAll()
				},
			},
		})
		.state('app.reports.summary.project.view.edit', {
			url: '/edit',
			onEnter: async function ($state, $document, $mdDialog, project) {
				$mdDialog
					.show({
						templateUrl: 'app/states/reports/reports-summary-project-edit.html',
						controller: 'ReportsSummaryProjectEditStateController',
						controllerAs: 'vm',
						bindToController: true,
						clickOutsideToClose: true,
						parent: angular.element($document.body),
						locals: { project },
					})
					.finally(function () {
						$state.go('^')
					})
			},
		})
}
