import template from './guest.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($scope, $log, authService, $state, $localStorage) {
	var vm = this
	vm.$onInit = () => {
		vm.title = 'NEXT - BOND'

		$scope.$watch(
			authService.isLoggedIn,
			function (newVal) {
				if (newVal) {
					if ($localStorage.targetState) {
						var ts = angular.fromJson($localStorage.targetState)
						$state.go(ts.name, ts.params)
						delete $localStorage.targetState
					} else {
						$state.go('app.dashboard.index')
					}
				}
			},
			true,
		)
	}
}
