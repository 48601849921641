import t from './day-hours-form.html'
export default {
	controller,
	controllerAs: 'vm',
	template: t,
	bindings: {
		asUser: '<',
		hour: '<',
		favorite: '<',
	},
}

export const template = t

/* @ngInject */
export function controller(
	$rootScope,
	$state,
	$stateParams,
	$mdDialog,
	$log,
	authService,
	Hour,
	Favorite,
	Project,
	Deliverable,
	Version,
	Task,
	$mdToast,
	favorite,
	hour,
) {
	var vm = this

	//API methods
	vm.cancel = cancel
	vm.save = save
	vm.isNew = isNew
	vm.newVersion = newVersion

	//Scope attributes
	vm.authService = authService
	vm.statusOptions = Hour.meta.statusLabels
	vm.Project = Project
	vm.Deliverable = Deliverable
	vm.Version = Version
	vm.Task = Task
	vm.tasks = null

	vm.$onInit = function () {
		if (vm.isNew()) {
			vm.model = buildNewHour()
		} else {
			vm.model = hour?.cloneFields()
		}
		vm.tasks = vm.asUser.tasks
	}

	function buildNewHour() {
		var hour = Hour.createInstance()
		if (favorite) {
			hour.favorite_id = favorite.id
			hour.project_id = favorite.project_id
			hour.deliverable_id = favorite.deliverable_id
			hour.version_id = favorite.version_id
			hour.task_id = favorite.task_id
		}
		hour.date = $stateParams.date
		hour.status = Hour.meta.statuses.active
		hour.user_id = vm.asUser.id

		return hour
	}

	function cancel() {
		close()
	}

	function close() {
		$mdDialog.cancel()
	}

	function isNew() {
		return (
			($state.current.name || $state.transition?.to?.().name).substr(-4) ===
			'.new'
		)
	}

	function save() {
		var promise

		vm.model.should_update_favorite = true
		if (vm.isNew()) {
			promise = Hour.create(vm.model)
		} else {
			promise = Hour.update(vm.model.id, vm.model)
		}

		return promise.then(function (hour) {
			Favorite.refreshAll()
			close()
			return hour
		})
	}

	function getAllVersions(deliverable_id, bypassCache) {
		bypassCache = !!bypassCache
		return Version.findAll(
			{ deliverable_id: deliverable_id },
			{ bypassCache: bypassCache },
		).then(function (versions) {
			vm.versions = versions
			return versions
		})
	}

	function subsequentVersionNumber() {
		return vm.versions.reduce(function (accum, version) {
			try {
				var name = version.name || ''
				var prevNumber = parseInt(name.split(' ')[0])
				if (prevNumber >= accum) {
					return prevNumber + 1
				}
			} catch (e) {
				$log.warn(e)
			}

			return accum
		}, 1)
	}

	function newVersion() {
		getAllVersions(vm.model.deliverable_id, true).then(function (versions) {
			var versionName = prompt(
				'Enter name of new version',
				subsequentVersionNumber().toString(),
			)

			if (!versionName) {
				$mdToast.show(
					$mdToast
						.simple()
						.textContent('Please enter a version name!')
						.position('top right')
						.hideDelay(6000)
						.theme('error'),
				)
				return
			}
			Version.create({
				deliverable_id: vm.model.deliverable_id,
				name: versionName,
			}).then(function (version) {
				vm.model.version_id = version.id
				return version
			})

			return versions
		})
	}
}
