import { clone as _clone } from 'lodash'
import moment from 'moment'
Project.autoInjectAs = 'Project'

/* @ngInject */
export default function Project(DS) {
	var statuses = {
		active: 'status_active',
		archived: 'status_archived',
	}
	var statusLabels = {
		status_active: 'Active',
		status_archived: 'Archived',
	}

	return DS.defineResource({
		name: 'project',
		endpoint: 'projects',
		computed: {
			created_at_short: ['created_at', createdAtShort],
			status_readable: ['status', readableStatus],
			display_name: ['name', 'job_code', 'job_number', displayName],
			current_margin: ['budget', 'spend_total', calculateCurrentMargin],
			production_days_remaining: [
				'production_end_date',
				calculateProductionDaysRemaining,
			],
		},
		meta: {
			statuses: statuses,
			statusLabels: statusLabels,
		},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					property_id: this.property_id,
					name: this.name,
					production_start_date: this.production_start_date
						? moment(this.production_start_date).toDate()
						: null,
					production_end_date: this.production_end_date
						? moment(this.production_end_date).toDate()
						: null,
					job_code: this.job_code,
					job_number: this.job_number,
					budget: this.budget,
					margin: Number.isFinite(this.margin) ? this.margin : 55,
					team_ids: _clone(this.team_ids),
					dashboard_user_ids: _clone(this.dashboard_user_ids || []),
					status: this.status,
				}
			},
			budgetSpentPercent: function () {
				return Math.round((this.spend_total / this.budget) * 100) || 0
			},
			daysToBudget: function () {
				/*
				 *Estimate of the number of days left until spending meets the budget
				 */
				var age = moment().diff(moment(this.created_at_date), 'days')
				var rate = (this.spend_total + this.expenses_total) / age
				var budgetLeft = this.budget - this.spend_total
				var daysLeft = budgetLeft / rate
				return Math.max(0, Math.round(daysLeft))
			},
		},
		relations: {
			belongsTo: {
				property: [
					{
						localField: 'property',
						localKey: 'property_id',
					},
				],
			},
			hasMany: {
				user: {
					localField: 'users',
					foreignKey: 'project_id',
				},
				deliverable: {
					localField: 'deliverables',
					foreignKey: 'project_id',
				},
				team: {
					localField: 'teams',
					localKeys: 'team_ids',
				},
				expense: {
					localField: 'expenses',
					get: function (Project, relationDef, project, origGetter) {
						return DS.filter('expense', {
							expensable_type: 'Project',
							expensable_id: project.id,
						})
					},
					load: function (Project, relationDef, project, options) {
						return DS.findAll('expense', {
							expensable_type: 'Project',
							expensable_id: project.id,
						})
					},
				},
			},
		},
	})

	function createdAtDate(created_at) {
		return new Date(created_at)
	}

	function createdAtShort(created_at) {
		return moment(created_at).format('MM/DD/YYYY')
	}

	function readableStatus(status) {
		return statusLabels.hasOwnProperty(status) ? statusLabels[status] : ''
	}

	function displayName(name, job_code, job_number) {
		return name + ' ' + '(' + job_code + ' ' + job_number + ')'
	}

	function calculateCurrentMargin(budget, spend_total) {
		let margin = 0
		if (Number.isFinite(budget) && Number.isFinite(spend_total) && budget > 0) {
			margin = ((budget - spend_total) / budget) * 100
		}
		return margin
	}

	function calculateProductionDaysRemaining(production_end_date) {
		return production_end_date
			? Math.max(moment(production_end_date).diff(moment(), 'days'), 0)
			: 0
	}
}

/* @ngInject */
function runBlockProject(Project) {
	window.Project = Project // eslint-disable-line
}
