import { lowerCase } from 'lodash'
import template from './autocomplete-base-directive.html'
import inject from 'inject'

@inject('$scope', '$element', '$q', '$timeout')
class controller {
	async $onInit() {
		// Scope Attributes
		this.title = this.primaryModel.name
		this.nameField = this.nameField || 'display_name'
		this.autocompleteField = {}
		this.autoCompleteArray = []
		this.element = this.$element

		this.$scope.$watchGroup(['vm.dependentRelation', 'vm.selectedId'], () => {
			this.getAllObjects().then((newMatches) => {
				this.selectById(this.selectedId)
				return newMatches
			})
		})

		this.getAllObjects().then((newMatches) => {
			this.selectById(this.selectedId)
			if (this.mdAutofocus) {
				this.$timeout(() => {
					this.$element.find('input').focus()
				}, 100)
			}
			return newMatches
		})

		this.autocompleteField.querySearch = (query) => {
			var results = []
			if (query) {
				results = this.autoCompleteArray.filter(
					((query) => {
						var searchTerms = lowerCase(query).split(' ')
						return (filteredItem) => {
							const searchString = lowerCase(filteredItem[this.nameField])
							return searchTerms.every((term) => {
								const regex = new RegExp(`\\b${term}`)
								return regex.test(searchString)
							})
						}
					})(query),
				)
			} else {
				results = this.autoCompleteArray
			}
			return results
		}

		this.autocompleteField.selectedItemChange = (item) => {
			if (item) {
				this.selectedId = item.id
			}
		}
	}

	async getAllObjects() {
		var relationshipsSatisfied = true
		angular.forEach(this.dependentRelation, (value) => {
			if (!value) {
				relationshipsSatisfied = false
			}
		})

		if (!relationshipsSatisfied) {
			this.autoCompleteArray = []
			this.autocompleteField.isDisabled = true
			return this.$q.reject('Waiting on dependent relationship')
		} else if (this.optionsList) {
			return this.$q.resolve(this.setAllObjects(this.optionsList))
		} else {
			return this.primaryModel
				.findAll(this.dependentRelation || {}, { bypassCache: true })
				.then((newMatches) => {
					return this.setAllObjects(newMatches)
				})
		}
	}

	setAllObjects(list) {
		this.autocompleteField.isDisabled = false
		this.autoCompleteArray = list
		return list
	}

	selectById(itemId) {
		var matchedExisting = false
		angular.forEach(this.autoCompleteArray, (object) => {
			if (object.id === itemId) {
				matchedExisting = true
				this.setSelected(object)
			}
		})

		// clear selection if the selectedId is somehow not in the list of returned objects
		if (this.autocompleteField.selectedItem && !matchedExisting) {
			this.selectedId = null
			this.autocompleteField.selectedItem = null
			this.autocompleteField.searchText = ''
		}
	}

	setSelected(selectedItem) {
		return (this.autocompleteField.selectedItem = selectedItem)
	}
}

export default {
	template,
	controller,
	controllerAs: 'vm',
	bindings: {
		primaryModel: '<',
		optionsList: '=',
		selectedId: '=',
		dependentRelation: '=',
		requireMatch: '@',
		mdAutofocus: '=?',
		nameField: '@',
	},
}
