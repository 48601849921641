export default {
	controllerAs: 'vm',
	templateUrl: 'app/components/hours/day-tags-directive.html',
	controller: dayTagsController,
	bindings: {
		view: '@',
		date: '=',
		user: '=',
	},
}

/** @ngInject */
function dayTagsController(
	$scope,
	$element,
	$mdDialog,
	User,
	OvertimeRequest,
	hoursService,
	overtimeRequestsService,
) {
	var vm = this
	vm.$onInit = () => {
		// API
		vm.hoursService = hoursService
		vm.overtimeRequestsService = overtimeRequestsService
		vm.showUserRejectionNote = showUserRejectionNote
		vm.OvertimeRequest = OvertimeRequest

		vm.view = vm.view || 'user'

		var bindAllUnbind = OvertimeRequest.bindAll(
			{
				user_id: vm.user.id,
				overtime_date: vm.date.format('YYYY-MM-DD'),
			},
			$scope,
			'vm.otrs',
		)

		$scope.$on('$destroy', bindAllUnbind)

		function showUserRejectionNote($event) {
			$event.stopPropagation()
			User.find(vm.user.hours_rejected_by_user_id).then(function (supervisor) {
				$mdDialog.show(
					$mdDialog
						.alert()
						.title('Changes Requested by ' + supervisor.full_name)
						.textContent(vm.user.hours_rejected_note)
						.ok('Close'),
				)
			})
		}
	}
}
