import template from './versions-new.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller(
	$scope,
	$state,
	$stateParams,
	$mdDialog,
	$log,
	Version,
	Property,
) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.save = save

		//Scope attributes
		vm.version = Version.createInstance()
		vm.statusOptions = Version.meta.statusLabels

		activate()
		function activate() {
			vm.version.status = Version.meta.statuses.active
			Property.findAll().then(function (properties) {
				vm.properties = properties
			})
		}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function save() {
			vm.version.deliverable_id = $stateParams.deliverable_id
			Version.create(vm.version).then(function (version) {
				close()
			})
		}
	}
}
