import template from './deliverables-new.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller(
	$scope,
	$state,
	$stateParams,
	$mdDialog,
	$log,
	Deliverable,
	Property,
	deliverableDescriptions,
) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.save = save
		vm.searchDescriptions = searchDescriptions

		//Scope attributes
		vm.deliverable = Deliverable.createInstance()
		vm.statusOptions = Deliverable.meta.statusLabels

		activate()
		function activate() {
			vm.deliverable.name = 'Untitled'
			vm.deliverable.status = Deliverable.meta.statuses.active
			Property.findAll().then(function (properties) {
				vm.properties = properties
			})
			getNextNumber()
		}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function save() {
			vm.deliverable.project_id = $stateParams.project_id
			Deliverable.create(vm.deliverable).then(function (deliverable) {
				close()
			})
		}

		function searchDescriptions(searchText) {
			searchText = (searchText || '').toLowerCase()
			return deliverableDescriptions.filter(function (i) {
				return i.toLowerCase().indexOf(searchText) !== -1
			})
		}

		function getNextNumber() {
			Deliverable.next_number({
				params: { project_id: $stateParams.project_id },
			}).then(function (resp) {
				vm.deliverable.number = resp.data.next_number
			})
		}
	}
}
