Notification.autoInjectAs = 'Notification'
import { findKey as _findKey } from 'lodash'
/* @ngInject */
export default function Notification(DS) {
	//status
	var statuses = {
		unread: 'status_unread',
		read: 'status_read',
		deleted: 'status_deleted',
	}
	var statusLabels = {
		unread: 'Unread',
		read: 'Read',
		deleted: 'Deleted',
	}

	return DS.defineResource({
		name: 'notification',
		endpoint: 'notifications',
		meta: {
			statuses: {
				unread: 'status_unread',
				read: 'status_read',
				deleted: 'status_deleted',
			},
		},
		relations: {
			belongsTo: {
				user: [
					{
						localKey: 'user_id',
						localField: 'user',
					},
					{
						localKey: 'target_user_id',
						localField: 'target_user',
					},
				],
			},
		},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					user_id: this.user_id,
					target_user_id: this.target_user_id,
					related_model_id: this.related_model_id,
					related_model_type: this.related_model_type,
					name: this.name,
					body: this.body,
					status: this.status,
				}
			},
			prettyStatus: function () {
				var currentStatus = this.status
				var statusKey = _findKey(statuses, function (val) {
					return val === currentStatus
				})
				return statusLabels[statusKey]
			},
		},
	})
}

/* @ngInject */
function runBlockNotification(Notification) {}
