import moment from 'moment'
import template from './week-hours-index.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		asUser: '<',
	},
}

/* @ngInject */
function controller(
	$scope,
	$state,
	$stateParams,
	$mdDialog,
	$mdToast,
	authService,
	Hour,
	hoursService,
) {
	var vm = this
	vm.$onInit = function () {
		// API
		vm.inDayFilter = inDayFilter
		vm.dayTotalHours = dayTotalHours
		vm.isLocked = isLocked
		vm.dateFromDay = dateFromDay
		vm.hoursService = hoursService

		// Scope Attributes
		vm.today = moment().startOf('day')
		vm.date = (
			$stateParams.date ? moment($stateParams.date) : moment(vm.today)
		).startOf('day')
		vm.weekStart = vm.date.startOf('week')
		vm.datePrevious = vm.weekStart.clone().subtract(1, 'weeks')
		vm.dateNext = vm.weekStart.clone().add(1, 'weeks')
		vm.dateThisWeek = moment().startOf('week')
		vm.isOnThisWeek = vm.dateThisWeek.isSame(vm.weekStart)
		vm.title = vm.asUser.full_name
		vm.sortedBy = 'project.name'
		vm.hoursSum = 0
		vm.daysOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

		activate()

		function activate() {
			// don't allow entering future date
			// if (vm.date.isAfter(vm.today)) {
			//   $mdToast.show(
			//     $mdToast.simple()
			//     .textContent("Fortune teller? No going into the future.")
			//     .position('top right')
			//     .hideDelay(6000)
			//     .theme('error')
			//   );
			//   $state.go('.', {date: vm.today.format('YYYY-MM-DD')});
			// }

			// vm.dateNext = vm.dateNext.isAfter(vm.today) ? null : vm.dateNext;
			getAllHours()
		}

		// private

		function getAllHours() {
			Hour.findAll(
				{
					user_id: vm.asUser.id,
					date_start: vm.weekStart.format('YYYY-MM-DD'),
					date_end: moment(vm.weekStart).add(1, 'week').format('YYYY-MM-DD'),
				},
				{ bypassCache: true },
			).then(function (hours) {
				vm.hours = hours
				vm.hoursSum = 0.0
				angular.forEach(hours, function (hour) {
					vm.hoursSum += parseFloat(hour.quantity) || 0
					Hour.loadRelations(hour, [
						'task',
						'project',
						'deliverable',
						'version',
					])
				})
			})
		}

		function inDayFilter(day) {
			return function (hour) {
				var weekDay = vm.dateFromDay(day) //moment of day of week
				return weekDay.isSame(hour.date, 'day')
			}
		}

		function dayTotalHours(day) {
			var sum = 0
			var hours = Hour.filter({
				date: vm.dateFromDay(day).format('YYYY-MM-DD'),
			})
			angular.forEach(hours, function (hour) {
				sum += parseFloat(hour.quantity)
			})
			return sum
		}

		function isLocked(day) {
			var date = vm.dateFromDay(day)
			return (
				date.isSame(vm.asUser.hours_lock_date) ||
				date.isBefore(vm.asUser.hours_lock_date)
			)
		}

		function dateFromDay(day) {
			return moment(vm.weekStart).day(vm.daysOfTheWeek.indexOf(day))
		}
	}
}
