// Force window reload instead of sneaky HMRs
if (module.hot) {
	module.hot.dispose(() => {
		window.location.reload()
	})
}

import 'jquery'
import angular from 'angular'

import 'angular-animate'
import 'angular-cookies'
import 'angular-chart.js'
import 'angular-dragdrop'
import 'angular-filter'
import 'angular-lazy-img/release/angular-lazy-img'
import 'angular-material'
import 'angular-messages'
import 'angular-sanitize'
import 'angular-toastr'
import 'angular-touch'
import 'angular-translate'
import '@uirouter/angularjs'
import 'angular-ui-sortable'
import 'js-data'
import 'js-data-angular'
import 'js-data-http'
import 'lodash'
import 'moment'
import 'ng-file-upload'
import 'ngstorage'

import { camelCase, pascalCase, paramCase } from 'change-case'

function addToModule({ module, name, type, component, componentHash }) {
	if (componentHash) {
		Object.keys(componentHash).forEach((key) => {
			const component = componentHash[key]
			const [name, type] = key.split('_')
			addToModule({ module, name: paramCase(name), type, component })
		})
	}

	if (typeof component !== 'undefined') {
		const args = [component]
		// everything but config and run blocks have the name as the first argument
		if (type === 'factory') {
			if (
				name.at(0) === name.at(0).toUpperCase() ||
				name.indexOf('-model') > -1
			) {
				args.unshift(pascalCase(name.replace('-model', '')))
			} else {
				args.unshift(camelCase(name))
			}
		} else if (!(type == 'config' || type == 'run')) {
			args.unshift(
				type == 'service' || type == 'controller'
					? pascalCase(name) // services and controllers start with uppercase
					: type == 'animation'
						? `.${name}` // animations must start with a . (but we'll make no further assumptions about the file name since it should already be dasherized)
						: camelCase(name),
			)
		}
		module[type](...args)
	}
}

import * as files from './app/**/*.js'

export const nowWebModule = angular.module('nowWeb', [
	'angular.filter',
	'angularLazyImg',
	'chart.js',
	'js-data',
	'ngAnimate',
	'ngAria',
	'ngCookies',
	'ngDragDrop',
	'ngFileUpload',
	'ngMaterial',
	'ngMessages',
	'ngSanitize',
	'ngStorage',
	'pascalprecht.translate',
	'toastr',
	'ui.router',
	'ui.sortable',
])

const flattenedLibFiles = {}
function deep(obj, path = '') {
	for (const key of Object.keys(obj)) {
		if (typeof obj[key] === 'object' && 'default' in obj[key]) {
			if (flattenedLibFiles[key]) {
				flattenedLibFiles[path + key] = obj[key]
			} else {
				flattenedLibFiles[key] = obj[key]
			}
		} else if (typeof obj[key] === 'object') deep(obj[key], path + key)
	}
}
deep(files)

for (const match of Object.keys(flattenedLibFiles).map((x) =>
	x.match(
		/(.*)\.(controller|directive|component|run|config|service|factory|filter|constant|animation)/,
	),
)) {
	if (!match) continue

	const [filename, name, kind] = match
	const component = flattenedLibFiles[filename].default
	addToModule({ module: nowWebModule, name, type: kind, component })

	/*
	 * In order to support our ui-router pattern of mdDialog-based modal routes,
	 * we additionally attach state components' controllers to the module.
	 */
	if (typeof component.controller === 'function') {
		addToModule({
			module: nowWebModule,
			name: `${name}Controller`,
			type: 'controller',
			component: component.controller,
		})
	}
}
