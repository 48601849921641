Organization.autoInjectAs = 'Organization'
/* @ngInject */
export default function Organization(DS) {
	var organizationSchema = {
		type: 'object',
		title: 'Organization',
		properties: {
			name: {
				title: 'Name',
				type: 'string',
				maxLength: 128,
			},
			status: {
				type: 'string',
				enum: [
					'status_active',
					'status_pending',
					'status_suspended',
					'status_deleted',
				],
			},
		},
		required: ['status', 'name'],
	}
	return DS.defineResource({
		name: 'organization',
		endpoint: 'organizations',
		methods: {
			status_options: function () {
				return {
					status_active: 'active',
					status_pending: 'pending',
					status_suspended: 'suspended',
					status_deleted: 'deleted',
				}
			},
			getSchema: function () {
				return organizationSchema
			},
			cloneFields: function () {
				return {
					id: this.id,
					name: this.name,
					status: this.status,
				}
			},
		},
	})
}

/* @ngInject */
function runBlockOrganization(Organization) {}
