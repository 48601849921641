/** @ngInject */
export default function config($stateProvider) {
	$stateProvider
		.state('guest', {
			url: '',
			abstract: true,
			views: {
				base: {
					templateUrl: 'app/layouts/base.html',
					controller: 'AppController as appController',
				},
			},
		})
		.state('guest.login', {
			url: '/',
			component: 'guestIndexState',
			resolve: {
				/** @ngInject */
				loggedIn: function ($q, $state, authService) {
					var deferred = $q.defer()

					authService.checkAccessPromise(authService.roles.user).then(
						function (user) {
							$state.go('app.dashboard.index')
							deferred.reject()
						},
						function () {
							deferred.resolve()
						},
					)

					return deferred.promise
				},
			},
		})
}
