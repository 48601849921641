import template from './reports-summary-project-index.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller(Project, $rootScope, $state, $scope, authService) {
	var vm = this
	vm.$onInit = () => {
		// API
		vm.gotoProject = gotoProject
		vm.projectNameFilter = projectNameFilter

		// Scope attributes
		vm.title = 'Project Health'
		vm.projects = []
		vm.projectsOrderBy = '-created_at'
		vm.projectNameSearch = null
		vm.projectNameSearchTerms = []

		$scope.$watch('vm.projectNameSearch', () => {
			if (vm.projectNameSearch) {
				vm.projectNameSearchTerms = vm.projectNameSearch
					.toLowerCase()
					.split(' ')
			} else {
				vm.projectNameSearchTerms = []
			}
		})

		authService
			.checkAccessPromise(authService.roles.admin)
			.then(() => {
				// Admins see all projects
				vm.projectsOrderBy = '+name'
				vm.projects = Project.filter({
					where: {
						status: Project.meta.statuses.active,
					},
				})
			})
			.catch(() => {
				// Managers see all projects they are authorized for
				vm.projectsOrderBy = '-created_at'
				vm.projects = Project.filter({
					where: {
						status: Project.meta.statuses.active,
						dashboard_user_ids: {
							contains: $rootScope.currentUser.id,
						},
					},
				})
			})

		function gotoProject(project) {
			$state.go('app.reports.summary.project.view', { project_id: project.id })
		}

		function projectNameFilter(item, index, array) {
			if (vm.projectNameSearchTerms && vm.projectNameSearchTerms.length > 0) {
				const projectSearchString =
					`${item.name} ${item.job_code} ${item.job_number}`.toLowerCase()
				return vm.projectNameSearchTerms.every((term) => {
					const regex = new RegExp(`\\b${term}`)
					return regex.test(projectSearchString)
				})
			} else {
				return true
			}
		}
	}
}
