import template from './logout.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($scope, $log, authService, $state) {
	var vm = this
	vm.$onInit = () => {
		vm.title = 'Logout'
		vm.logout = logout

		vm.logout() // immediately logout, skip the prompt

		function logout() {
			authService.logout()
			$state.go('guest.login')
		}
	}
}
