import template from './properties-edit.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($mdDialog, $log, Client, Property, property) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.update = update

		//Scope attributes
		vm.property = property.cloneFields()
		vm.statusOptions = Property.meta.statusLabels

		activate()
		function activate() {
			Client.findAll().then(function (clients) {
				vm.clients = clients
			})
		}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function update() {
			property.DSUpdate(vm.property).then(
				function (property) {
					close()
				},
				function (err) {
					$log.warn(err)
				},
			)
		}
	}
}
