/* @ngInject */
export default function AppController(
	$rootScope,
	$scope,
	$mdSidenav,
	$window,
	$timeout,
	$transitions,
	authService,
	webNotification,
	Notification,
	DS,
) {
	var vm = this
	vm.onResize = onResize
	vm.menuToggle = menuToggle
	vm.activityLogToggle = activityLogToggle
	vm.getTimestamp = getTimestamp
	vm.refreshNotifications = refreshNotifications
	vm.markAsRead = markAsRead

	vm.authService = authService

	var notificationFirstRun = true

	activate()
	function activate() {
		/*eslint-disable */
		$scope.viewport = {}
		/*eslint-enable */
		vm.viewport = {}
		vm.viewport.width = 0
		vm.viewport.height = 0
		vm.notifications = []
		vm.$window = angular.element($window)
		vm.$body = angular.element('body')
		vm.$window.resize(function () {
			$scope.$apply(vm.onResize)
		})
		vm.onResize()
		vm.refreshNotifications()
		recurringNotificationCheck()
		$rootScope.authService = authService

		$transitions.onSuccess(null, () => {
			$mdSidenav('main-menu').close()
		})
	}

	function menuToggle() {
		$mdSidenav('main-menu').toggle()
	}

	function activityLogToggle() {
		$mdSidenav('activity-log').toggle()
		vm.refreshNotifications()
	}

	function recurringNotificationCheck() {
		$timeout(function () {
			vm.refreshNotifications()
			recurringNotificationCheck()
		}, 30000)
	}

	function refreshNotifications() {
		if (!authService.isLoggedIn()) {
			return true
		}

		vm.refreshingNotifications = true
		Notification.findAll({}, { bypassCache: true }).then(
			function (notifications) {
				if (
					!notificationFirstRun &&
					notifications[0] &&
					notifications[0] !== vm.notifications[0]
				) {
					webNotification.sendNotification(
						notifications[0].name,
						notifications[0].body,
					)
				}
				notificationFirstRun = false
				vm.notifications = notifications
				vm.refreshingNotifications = false
			},
		)
	}

	function markAsRead(notification) {
		var model = notification.cloneFields()
		model.status = Notification.meta.statuses.read
		notification.DSUpdate(model).then(function (model) {
			notification = model
			return model
		})
	}

	function getTimestamp(t) {
		return new Date(t.created_at)
	}

	function onResize() {
		$scope.viewport.width = vm.$window.innerWidth()
		$scope.viewport.height = vm.$window.innerHeight() - 64
		vm.viewport.width = vm.viewport.width
		vm.viewport.height = vm.viewport.height
		$rootScope.$broadcast('viewportChanged')
	}
}
