export default function expenses() {
	var directive = {
		restrict: 'E',
		controller: expensesController,
		controllerAs: 'vm',
		scope: {},
		bindToController: {
			expensable: '=',
			shown: '=',
			name: '@',
			view: '@',
		},
		templateUrl: 'app/components/expenses/expenses-directive.html',
	}
	return directive
}

/** @ngInject */
function expensesController($scope, expensesService, Expense) {
	var vm = this

	// API
	vm.close = close
	vm.destroy = destroy
	vm.create = create
	vm.setViewShow = setViewShow
	vm.setViewCreate = setViewCreate

	// Scope Attributes
	vm.expensesService = expensesService
	vm.expenses = []

	vm.$onInit = function () {
		vm.view = vm.view || 'show'
		vm.expensableType = expensesService.expensableClassName(vm.expensable)

		vm.expensableParams = {
			expensable_type: vm.expensableType,
			expensable_id: vm.expensable.id,
		}

		var bindAllUnbind = angular.noop

		if (vm.expensable.DSLoadRelations) {
			vm.loading = true
			vm.expensable
				.DSLoadRelations('expenses')
				.then(function (expenses) {
					vm.expenses = expenses
					bindAllUnbind = Expense.bindAll(
						vm.expensableParams,
						$scope,
						'vm.expenses',
					)
				})
				.finally(function () {
					vm.loading = false
				})
		}

		$scope.$on('$destroy', bindAllUnbind)
	}

	function close() {
		vm.shown = false
	}

	function destroy(expense) {
		var promise
		vm.loading = true

		if (expense.id) promise = _destroyForPersisted(expense)
		else promise = _destroyForUnPersisted(expense)

		promise.finally(function () {
			vm.loading = false
		})
	}

	function _destroyForPersisted(expense) {
		/*
		 * Remove an expense for a persisted record by API-destroying it and
		 * allowing `bindAll` above to handle removal from scope set
		 */
		return expense.DSDestroy().then(function (expense) {
			vm.expensable.DSRefresh()
			return Expense.eject(expense)
		})
	}

	function _destroyForUnPersisted(expense) {
		return new Promise(function (resolve, _reject) {
			resolve(vm.expenses.splice(vm.expenses.indexOf(expense), 1))
		})
	}

	function create() {
		var promise
		vm.loading = true

		if (vm.expensable.id) promise = _createForPersisted(vm.expensable)
		else promise = _createForUnPersisted(vm.expensable)

		promise.finally(function () {
			vm.loading = false
			setViewShow()
		})
	}

	function _createForPersisted(expensable) {
		/*
		 * For an existing expensable instance, we can just create
		 * expenses directly via the expenses API action
		 */

		vm.newExpense.expensable_id = expensable.id
		vm.newExpense.expensable_type = vm.expensableType
		vm.newExpense.status = Expense.meta.statuses.active
		return Expense.create(vm.newExpense).then(function (expense) {
			Expense.inject(expense)
			expensable.DSRefresh()
			return expense
		})
	}

	function _createForUnPersisted(expensable) {
		/*
		 * For a new, unsaved expensable (eg: new project form)
		 * embed into expensable attributes
		 */

		return new Promise(function (resolve, _reject) {
			vm.newExpense.expensable_id = expensable.id
			vm.newExpense.expensable_type = expensable.expensableType
			vm.newExpense.status = Expense.meta.statuses.active
			vm.expenses.push(vm.newExpense)
			vm.expensable.expenses = vm.expenses
			resolve(vm.newExpense)
		})
	}

	function setViewShow() {
		vm.view = 'show'
	}

	function setViewCreate() {
		vm.newExpense = {}
		vm.view = 'create'
	}
}
