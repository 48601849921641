/** @ngInject */
export default function moneyMask($filter, $timeout) {
	var directive = {
		restrict: 'A',
		require: '?ngModel',
		scope: {
			moneyMask: '=',
		},
		link: function (scope, elem, attrs, ctrl) {
			if (!ctrl) return

			elem.bind('blur', reformat)
			elem.bind('keyup', reformat)
			$timeout(reformat, 0)

			function reformat(e) {
				var start = elem[0].selectionStart
				var end = elem[0].selectionEnd
				var ogValue = elem.val()
				var length = ogValue.length

				var plainNumber = ogValue.replace(/[^\d|\-+]/g, '') // replace(/[^\d|\-+|\.+]/g, '');
				var newValue = $filter('currency')(plainNumber, '$', 0)
				if (plainNumber.length > 0 && ogValue !== newValue) {
					elem.val(newValue)

					var delta = elem.val().length - length
					if (delta > 1) {
						start += 2
						end += 2
					} else if (delta === 1) {
						start++
						end++
					}

					elem[0].setSelectionRange(start, end)
				}
			}
		},
	}

	return directive
}
