import template from './clients-edit.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($mdDialog, $log, Client, client) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.update = update

		//Scope attributes
		vm.statusOptions = Client.meta.statusLabels
		vm.client = client.cloneFields()

		activate()
		function activate() {}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function update() {
			client.DSUpdate(vm.client).then(
				function (client) {
					close()
				},
				function (err) {
					$log.warn(err)
				},
			)
		}
	}
}
