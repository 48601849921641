import moment from 'moment'
OvertimeRequest.autoInjectAs = 'OvertimeRequest'

/* @ngInject */
export default function OvertimeRequest(DS) {
	var statuses = {
		pending: 'status_pending',
		rejected: 'status_rejected',
		approved: 'status_approved',
		deleted: 'status_deleted',
	}
	var statusLabels = {
		status_pending: 'Pending',
		status_rejected: 'Not Approved',
		status_approved: 'Approved',
		status_deleted: 'Deleted',
	}

	return DS.defineResource({
		name: 'overtimeRequest',
		endpoint: 'overtime_requests',
		actions: {},
		computed: {
			created_at_short: ['created_at', createdAtShort],
			status_readable: ['status', readableStatus],
			auditable: ['status', auditable],
		},
		meta: {
			statuses: statuses,
			statusLabels: statusLabels,
		},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					organization_id: this.organization_id,
					memo: this.memo,
					user_id: this.user_id,
					overtime_date: this.overtime_date,
					audited_by_id: this.audited_by_id,
					audited_note: this.audited_note,
					status: this.status,
				}
			},
		},
		relations: {
			belongsTo: {
				user: [
					{
						localField: 'user',
						localKey: 'user_id',
					},
					{
						localField: 'auditor',
						localKey: 'audited_by_id',
					},
				],
			},
		},
	})

	function createdAtDate(created_at) {
		return new Date(created_at)
	}

	function createdAtShort(created_at) {
		return moment(created_at).format('MM/DD/YYYY')
	}

	function readableStatus(status) {
		return statusLabels.hasOwnProperty(status) ? statusLabels[status] : ''
	}

	function auditable(status) {
		return status != statuses.approved && status != statuses.rejected
	}
}

/* @ngInject */
function runOvertimeRequest(OvertimeRequest) {}
