export default function helper() {
	var directive = {
		restrict: 'E',
		controller: helperController,
		controllerAs: 'vm',
		transclude: true,
		scope: {},
		bindToController: {
			view: '@',
		},
		templateUrl: 'app/components/helpers/helper-directive.html',
	}
	return directive
}

/** @ngInject */
function helperController($scope, $document, $element) {
	/*jshint validthis:true*/
	var vm = this

	// API
	vm.close = close

	// Scope Attributes
	vm.shown = false

	activate()
	function activate() {}

	function close() {
		vm.shown = false
	}
}
