const _temp0 = require("./download.js.factory.js");
const _temp1 = require("./exception-notifier.factory.js");

const _temp2 = require("../auth/auth-service.factory.js");

const _temp3 = require("../clients/Client.factory.js");

const _temp4 = require("../deliverables/Deliverable.factory.js");

const _temp5 = require("../departments/Department.factory.js");

const _temp6 = require("../expenses/Expense.factory.js");
const _temp7 = require("../expenses/expenses-service.factory.js");

const _temp8 = require("../hours/Favorite.factory.js");
const _temp9 = require("../hours/Hour.factory.js");
const _temp10 = require("../hours/hours-service.factory.js");

const _temp11 = require("../notifications/Notification.factory.js");
const _temp12 = require("../notifications/web-notification.factory.js");

const _temp13 = require("../organizations/Organization.factory.js");

const _temp14 = require("../overtime-requests/OvertimeRequest.factory.js");
const _temp15 = require("../overtime-requests/overtime-requests-service.factory.js");

const _temp16 = require("../projects/Project.factory.js");

const _temp17 = require("../properties/Property.factory.js");

const _temp18 = require("../reports/Report.factory.js");

const _temp19 = require("../tasks/Task.factory.js");

const _temp20 = require("../teams/Team.factory.js");

const _temp21 = require("../users/User.factory.js");

const _temp22 = require("../versions/Version.factory.js");

module.exports = {
  "_utils":   {
    "download.js": _temp0,
    "exception-notifier": _temp1
  },
  "auth":   {
    "auth-service": _temp2
  },
  "clients":   {
    "Client": _temp3
  },
  "deliverables":   {
    "Deliverable": _temp4
  },
  "departments":   {
    "Department": _temp5
  },
  "expenses":   {
    "Expense": _temp6,
    "expenses-service": _temp7
  },
  "hours":   {
    "Favorite": _temp8,
    "Hour": _temp9,
    "hours-service": _temp10
  },
  "notifications":   {
    "Notification": _temp11,
    "web-notification": _temp12
  },
  "organizations":   {
    "Organization": _temp13
  },
  "overtime-requests":   {
    "OvertimeRequest": _temp14,
    "overtime-requests-service": _temp15
  },
  "projects":   {
    "Project": _temp16
  },
  "properties":   {
    "Property": _temp17
  },
  "reports":   {
    "Report": _temp18
  },
  "tasks":   {
    "Task": _temp19
  },
  "teams":   {
    "Team": _temp20
  },
  "users":   {
    "User": _temp21
  },
  "versions":   {
    "Version": _temp22
  }
}