const deliverableDescriptions = [
	'Trailer',
	'Teaser',
	'TV 60',
	'TV 45',
	'TV 30',
	'TV 15',
	'Digital',
	'Radio',
	'Special Shoot',
	'BTS',
	'Bumper',
	'Sizzle',
	'Motion',
	'Clip Pull',
	'GIF',
	'Next On',
	'1x1',
]
export default deliverableDescriptions
