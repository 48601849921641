import moment from 'moment'

Hour.autoInjectAs = 'Hour'
/* @ngInject */
export default function Hour(DS) {
	var statuses = {
		active: 'status_active',
		archived: 'status_archived',
	}
	var statusLabels = {
		status_active: 'Active',
		status_archived: 'Archived',
	}

	return DS.defineResource({
		name: 'hour',
		endpoint: 'hours',
		actions: {
			csv: {
				method: 'GET',
			},
			lock: {
				method: 'POST',
			},
			unlock: {
				method: 'POST',
			},
			reject: {
				method: 'POST',
			},
			approve: {
				method: 'POST',
			},
		},
		computed: {
			created_at_short: ['created_at', createdAtShort],
			status_readable: ['status', readableStatus],
		},
		meta: {
			statuses: statuses,
			statusLabels: statusLabels,
		},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					user_id: this.user_id,
					task_id: this.task_id,
					version_id: this.version_id,
					deliverable_id: this.deliverable_id,
					project_id: this.project_id,
					date: this.date,
					quantity: this.quantity,
					cost: this.cost,
					status: this.status,
					memo: this.memo,
				}
			},
		},
		relations: {
			belongsTo: {
				user: [
					{
						localField: 'user',
						localKey: 'user_id',
					},
				],
				task: [
					{
						localField: 'task',
						localKey: 'task_id',
					},
				],
				version: [
					{
						localField: 'version',
						localKey: 'version_id',
					},
				],
				deliverable: [
					{
						localField: 'deliverable',
						localKey: 'deliverable_id',
					},
				],
				project: [
					{
						localField: 'project',
						localKey: 'project_id',
					},
				],
			},
		},
	})

	function createdAtDate(created_at) {
		return new Date(created_at)
	}

	function createdAtShort(created_at) {
		return moment(created_at).format('MM/DD/YYYY')
	}

	function readableStatus(status) {
		return statusLabels.hasOwnProperty(status) ? statusLabels[status] : ''
	}
}

/* @ngInject */
function runBlockHour(Hour) {}
