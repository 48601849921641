import template from './reports-hours-week.html'
import moment from 'moment'
export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		users: '<',
	},
}

/* @ngInject */
function controller(
	$scope,
	$state,
	$stateParams,
	$filter,
	$document,
	$mdDialog,
	$log,
	Report,
	User,
	hoursService,
	overtimeRequestsService,
) {
	var vm = this
	vm.$onInit = () => {
		// API
		vm.downloadHours = downloadHours
		vm.orderByManager = orderByManager
		vm.hoursService = hoursService
		vm.overtimeRequestsService = overtimeRequestsService
		vm.gotoUser = gotoUser
		vm.userNameFilter = userNameFilter

		// Scope attributes
		// vm.users provided by route in bindings
		vm.title = 'Timesheets'
		vm.report = {}
		vm.userTotals = {}
		vm.allTotals = {}
		vm.today = moment().hour(0).minute(0).second(0)
		vm.date = (
			$stateParams.date ? moment($stateParams.date) : moment()
		).startOf('week')
		vm.datePrevious = vm.date.clone().subtract(1, 'weeks')
		vm.dateNext = vm.date.clone().add(1, 'weeks')
		vm.dateThisWeek = moment().startOf('week')
		vm.isOnThisWeek = vm.dateThisWeek.isSame(vm.date)
		vm.week = [
			{
				label: 'Sun (' + vm.date.clone().day(0).format('MM/DD') + ')',
				date: vm.date.clone().day(0),
				dateString: vm.date.clone().day(0).format('YYYY-MM-DD'),
			},
			{
				label: 'Mon (' + vm.date.clone().day(1).format('MM/DD') + ')',
				date: vm.date.clone().day(1),
				dateString: vm.date.clone().day(1).format('YYYY-MM-DD'),
			},
			{
				label: 'Tue (' + vm.date.clone().day(2).format('MM/DD') + ')',
				date: vm.date.clone().day(2),
				dateString: vm.date.clone().day(2).format('YYYY-MM-DD'),
			},
			{
				label: 'Wed (' + vm.date.clone().day(3).format('MM/DD') + ')',
				date: vm.date.clone().day(3),
				dateString: vm.date.clone().day(3).format('YYYY-MM-DD'),
			},
			{
				label: 'Thur (' + vm.date.clone().day(4).format('MM/DD') + ')',
				date: vm.date.clone().day(4),
				dateString: vm.date.clone().day(4).format('YYYY-MM-DD'),
			},
			{
				label: 'Fri (' + vm.date.clone().day(5).format('MM/DD') + ')',
				date: vm.date.clone().day(5),
				dateString: vm.date.clone().day(5).format('YYYY-MM-DD'),
			},
			{
				label: 'Sat (' + vm.date.clone().day(6).format('MM/DD') + ')',
				date: vm.date.clone().day(6),
				dateString: vm.date.clone().day(6).format('YYYY-MM-DD'),
			},
		]
		vm.userNameSearch = null
		vm.userNameSearchTerms = []

		activate()
		function activate() {
			$scope.$watch('vm.userNameSearch', () => {
				if (vm.userNameSearch) {
					vm.userNameSearchTerms = vm.userNameSearch.toLowerCase().split(' ')
				} else {
					vm.userNameSearchTerms = []
				}
			})

			Report.hours_range_by_user({
				params: {
					start_date: vm.date.format('YYYY-MM-DD'),
					end_date: vm.dateNext.format('YYYY-MM-DD'),
				},
			}).then(function (response) {
				vm.report = $filter('groupBy')(response.data, 'user_id')
				vm.userTotals = {}
				vm.allTotals = {}
				angular.forEach(vm.report, function (hours, userid) {
					angular.forEach(hours, function (el, idx) {
						var qty = parseFloat(el.quantity)
						vm.userTotals[el.user_id] = vm.userTotals[el.user_id] || 0
						vm.userTotals[el.user_id] += qty
						vm.allTotals['total'] = vm.allTotals['total'] || 0
						vm.allTotals['total'] += qty
						vm.allTotals[el.date] = vm.allTotals[el.date] || 0
						vm.allTotals[el.date] += qty
					})
				})
			})
		}

		function downloadHours(ev) {
			$mdDialog
				.show({
					templateUrl: 'app/states/reports/reports-download-hours.html',
					controller: 'ReportsDownloadHoursStateController',
					controllerAs: 'vm',
					clickOutsideToClose: true,
					parent: angular.element($document.body),
					targetEvent: ev,
					bindToController: true,
					locals: {
						dateStart: vm.date,
						dateEnd: vm.dateNext.clone().subtract(1, 'days'),
					},
				})
				.then(
					function () {
						$log.info('Closed normally.')
					},
					function () {},
				)

			// Hour.csv({ params: {
			//   date_start: vm.date.format('YYYY-MM-DD'),
			//   date_end: vm.dateNext.format('YYYY-MM-DD')
			// }}).then(function(response){
			//   downloadjs.download(response.data, "hours-report.csv", "text/csv");
			// });
		}

		function orderByManager(manager) {
			return function (user) {
				return manager.reportee_ids.indexOf(user.id) == -1
			}
		}

		function gotoUser(user) {
			$state.go('app.reports.hours.user', {
				user_id: user.id,
				date: vm.date.format('YYYY-MM-DD'),
			})
		}

		function userNameFilter(item, index, array) {
			if (vm.userNameSearchTerms && vm.userNameSearchTerms.length > 0) {
				const userSearchString =
					`${item.full_name} ${item.first_name} ${item.last_name}`.toLowerCase()
				return vm.userNameSearchTerms.every((term) => {
					const regex = new RegExp(`\\b${term}`)
					return regex.test(userSearchString)
				})
			} else {
				return true
			}
		}
	}
}
