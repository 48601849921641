export default function projectSpendChip() {
	var directive = {
		restrict: 'E',
		controller: projectSpendChipController,
		controllerAs: 'vm',
		scope: {},
		bindToController: {
			project: '=',
		},
		templateUrl: 'app/components/projects/project-spend-chip-directive.html',
	}
	return directive
}

/** @ngInject */
function projectSpendChipController($scope, $document, $element) {
	/*jshint validthis:true*/
	var vm = this

	vm.budgetSpent = vm.project.budgetSpentPercent()
	vm.budgetRemainingPercent = 100 - vm.budgetSpent
}
