/** @ngInject */
export default function authConfig($provide, $httpProvider) {
	$provide.factory('AuthHttpInterceptor', AuthHttpInterceptor)

	/** @ngInject */
	function AuthHttpInterceptor($q, $log, $localStorage, configApiBase) {
		return {
			// optional method
			request: function (config) {
				if (config.url.indexOf(configApiBase) === 0) {
					config.headers = config.headers || {}
					config.withCredentials = true
					config.headers['X-Auth-Token'] = $localStorage.token
					config.headers['X-Auth-Email'] = $localStorage.email
				}
				return config
			},

			// optional method
			requestError: function (rejection) {
				// do something on error
				return $q.reject(rejection)
			},

			// optional method
			response: function (response) {
				// do something on success
				return response
			},

			// optional method
			responseError: function (rejection) {
				// do something on error
				return $q.reject(rejection)
			},
		}
	}

	$httpProvider.interceptors.push('AuthHttpInterceptor')
}
