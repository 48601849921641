export default function projectThermometer() {
	var directive = {
		restrict: 'E',
		controller: projectThermometerController,
		controllerAs: 'vm',
		scope: {},
		bindToController: {
			project: '=',
		},
		templateUrl: 'app/components/projects/project-thermometer-directive.html',
	}
	return directive
}

/** @ngInject */
function projectThermometerController($scope, $document, $element) {
	/*jshint validthis:true*/
	var vm = this

	vm.budgetSpent = vm.project?.budgetSpentPercent()
	vm.budgetRemainingPercent = 100 - vm.budgetSpent
}
