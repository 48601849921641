import template from './me-profile.html'

/* @ngInject */
function controller($rootScope, User, $log) {
	var vm = this
	vm.$onInit = () => {
		// API
		vm.update = update

		// Scope Attributes
		vm.title = 'My Profile'
		vm.me = $rootScope.currentUser.cloneFields()
		vm.fields = {
			first_name: {
				inputType: 'text',
				label: 'first name',
				required: true,
			},
			last_name: {
				inputType: 'text',
				label: 'last name',
				required: true,
			},
		}

		activate()

		function activate() {}

		function update(me) {
			User.update(me.id, me).then(
				function (me) {
					vm.me = me
				},
				function (err) {
					$log.debug(err)
				},
			)
		}
	}
}

export default {
	controller,
	controllerAs: 'vm',
	template,
}
