import inject from 'inject'
import _ from 'lodash'
import moment from 'moment'
import template from './reports-summary-project-view.html'
import { lowerCase } from 'lodash'

/* @ngInject */
@inject(
	'$scope',
	'$state',
	'$stateParams',
	'$filter',
	'$mdDialog',
	'$mdToast',
	'authService',
	'Report',
)
class controller {
	$onInit() {
		var vm = this

		// API
		vm.go1Month = go1Month
		vm.go3Months = go3Months
		vm.go6Months = go6Months
		vm.goAllTime = goAllTime
		vm.goCustom = goCustom

		// Scope Attributes
		vm.showCosts = false
		vm.dataField = 'hours'
		vm.today = moment().startOf('day')
		vm.dateStart = vm.$stateParams.date_start
			? moment(vm.$stateParams.date_start).startOf('day')
			: undefined
		// vm.dateStartField = vm.dateStart.toDate()
		vm.dateEnd = vm.$stateParams.date_end
			? moment(vm.$stateParams.date_end).startOf('day')
			: undefined
		// vm.dateEndField = vm.dateEnd.toDate()
		vm.title = vm.project.name + ' - ' + vm.project.job_code
		vm.tasksById = {}
		vm.usersById = {}
		vm.completionRate = { now: 0, weekAgo: 0 }
		vm.budgetUsageRate = { now: 0, weekAgo: 0 }
		vm.marginRate = { now: 0, weekAgo: 0 }
		vm.budgetUsageByDay = {}
		vm.budgetUsageChart = {}
		vm.hoursData = {}
		vm.chartOptions = {
			animation: {
				duration: 0,
			},
			legend: {
				display: true,
				position: 'left',
				labels: {
					boxWidth: 20,
					fontSize: 10,
				},
			},
		}

		vm.reloadDebounced = _.debounce(() => {
			vm.$state.reload()
		}, 1000)

		vm.$scope.$watchGroup(
			[
				'vm.project.budget',
				'vm.project.margin',
				'vm.project.production_start_date',
				'vm.project.production_end_date',
			],
			function (newValues, oldValues) {
				if (newValues.some((value, index) => value !== oldValues[index])) {
					vm.reloadDebounced()
				}
			},
		)
		activate()

		function activate() {
			setupAutocomplete()

			angular.forEach(vm.tasks, function (item, idx) {
				vm.tasksById[item.id] = item
			})

			angular.forEach(vm.users, function (item, idx) {
				vm.usersById[item.id] = item
			})

			getAllHours()
			getBudgetUsageByDay()
		}

		function getAllHours() {
			vm.Report.hours_range_by_user_task({
				params: {
					// Now that we show real costs, we only show all hours aggregated from the beginning of a project for privacy.
					// start_date: vm.dateStart
					// 	? vm.dateStart.format('YYYY-MM-DD')
					// 	: undefined,
					// end_date: vm.dateEnd
					// 	? vm.dateEnd.clone().add(1, 'days').format('YYYY-MM-DD')
					// 	: undefined,
					project_id: vm.project.id,
				},
			})
				.then(function (response) {
					vm.rawTotals = {}
					vm.taskTotals = {}
					vm.userTotals = {}
					if (response.data.length === 0) {
						vm.noData = true
					} else {
						angular.forEach(response.data, function (item, idx) {
							var qty = parseFloat(item.quantity)
							var cost = parseFloat(item.cost)
							vm.rawTotals[item.user_id] = vm.rawTotals[item.user_id] || {}
							vm.rawTotals[item.user_id][item.task_id] = vm.rawTotals[
								item.user_id
							][item.task_id] || { qty: 0, cost: 0 }
							vm.rawTotals[item.user_id][item.task_id].qty += qty
							vm.rawTotals[item.user_id][item.task_id].cost += cost
							vm.taskTotals[item.task_id] = vm.taskTotals[item.task_id] || {
								qty: 0,
								cost: 0,
							}
							vm.taskTotals[item.task_id].qty += qty
							vm.taskTotals[item.task_id].cost += cost
							vm.userTotals[item.user_id] = vm.userTotals[item.user_id] || {
								qty: 0,
								cost: 0,
							}
							vm.userTotals[item.user_id].qty += qty
							vm.userTotals[item.user_id].cost += cost
						})

						chartTaskHours()
						chartUserHours()
						prepSortableHours()
					}
					return response
				})
				.catch((e) => {
					if (e.status == 403) vm.hoursData.unauthorized = e.status === 403
					else throw e
				})
		}

		function getBudgetUsageByDay() {
			vm.Report.budget_usage_by_day({
				params: {
					project_id: vm.project.id,
				},
			})
				.then(function (response) {
					vm.budgetUsageByDay = {}
					if (response.data.length === 0) {
						vm.noData = true
					} else {
						vm.budgetUsageByDay = response.data

						chartBudgetUsage()
					}
					return response
				})
				.catch((e) => {
					if (e.status == 403) vm.hoursData.unauthorized = e.status === 403
					else throw e
				})
		}

		function chartTaskHours() {
			vm.taskChart = {}
			vm.taskChart.labels = []
			vm.taskChart.data = []
			vm.taskChart.map = []
			vm.taskChart.totalHours = 0
			vm.taskChart.totalCost = 0
			angular.forEach(vm.taskTotals, function (values, taskId) {
				var n = vm.tasksById[taskId].name
				var q = values.qty
				var c = values.cost
				vm.taskChart.map.push({ name: n, hours: q, cost: c })
				vm.taskChart.totalHours += q
				vm.taskChart.totalCost += c
			})
			vm.taskChart.map.sort(function (a, b) {
				if (a[vm.dataField] < b[vm.dataField]) {
					return 1
				} else if (a[vm.dataField] > b[vm.dataField]) {
					return -1
				} else {
					return 0
				}
			})
			angular.forEach(vm.taskChart.map, function (item, idx) {
				// var label = item.hours + (item.cost > 0 ? ' ($' + item.cost + ')' : '');
				vm.taskChart.labels.push(item.name)
				vm.taskChart.data.push(item[vm.dataField])
			})
		}

		function chartUserHours() {
			vm.jobChart = {}
			vm.jobChart.labels = []
			vm.jobChart.data = []
			vm.jobChart.map = []
			vm.jobChart.totalHours = 0
			vm.jobChart.totalCost = 0
			angular.forEach(vm.userTotals, function (values, userId) {
				var n = vm.usersById[userId].full_name
				var q = values.qty
				var c = values.cost
				vm.jobChart.map.push({ name: n, hours: q, cost: c })
				vm.jobChart.totalHours += q
				vm.jobChart.totalCost += c
			})
			vm.jobChart.map.sort(function (a, b) {
				if (a[vm.dataField] < b[vm.dataField]) {
					return 1
				} else if (a[vm.dataField] > b[vm.dataField]) {
					return -1
				} else {
					return 0
				}
			})
			angular.forEach(vm.jobChart.map, function (item, idx) {
				// var label = item[vm.dataField] + (item.cost > 0 ? ' ($' + item.cost + ')' : '');
				vm.jobChart.labels.push(item.name)
				vm.jobChart.data.push(item[vm.dataField])
			})
		}

		function chartBudgetUsage() {
			var today = moment().startOf('day')
			var productionStartDate = vm.project.production_start_date
				? moment(vm.project.production_start_date)
				: undefined
			var productionEndDate = vm.project.production_end_date
				? moment(vm.project.production_end_date)
				: undefined
			var productionDuration =
				productionStartDate && productionEndDate
					? productionEndDate.diff(productionStartDate, 'days') + 1
					: 0
			var hoursStartDate = moment(
				_.minBy(vm.budgetUsageByDay, (item) => moment(item.date)).date,
			)
			var hoursEndDate = moment(
				_.maxBy(vm.budgetUsageByDay, (item) => moment(item.date)).date,
			)
			var earliestDate = moment.min(
				[productionStartDate, hoursStartDate].filter(Boolean),
			)
			var latestDate = moment.max(
				[productionEndDate, hoursEndDate].filter(Boolean),
			)
			var totalDays = latestDate.diff(earliestDate, 'days') + 1
			var targetMargin = vm.project.margin / 100
			var projectBudgetAtMargin = vm.project.budget * (1 - targetMargin)
			var dailyBudget = projectBudgetAtMargin / productionDuration

			if (productionStartDate && productionEndDate) {
				let completionRateNow =
					((today.diff(productionStartDate, 'days') + 1) / productionDuration) *
					100
				let completionRateWeekAgo =
					((today
						.clone()
						.subtract(7, 'days')
						.diff(productionStartDate, 'days') +
						1) /
						productionDuration) *
					100
				vm.completionRate.now = Math.min(Math.max(completionRateNow, 0), 100)
				vm.completionRate.weekAgo = Math.min(
					Math.max(completionRateWeekAgo, 0),
					100,
				)
			}

			vm.budgetUsageChart = {}
			vm.budgetUsageChart.labels = []
			vm.budgetUsageChart.data = [[], []]
			vm.budgetUsageChart.series = ['Budget Usage', 'Budget Goal']
			vm.budgetUsageChart.totalHours = 0
			vm.budgetUsageChart.totalCost = 0
			vm.budgetUsageChart.options = {
				..._.cloneDeep(vm.chartOptions),
				tooltips: {
					callbacks: {
						label: function (tooltipItem, data) {
							let value =
								data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
							return `$${vm.$filter('number')(value, 2)}`
						},
					},
				},
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true,
							},
						},
					],
				},
			}
			vm.budgetUsageChart.datasetOverride = [
				{
					label: 'Budget Spent',
					type: 'line',
				},
				{
					label: 'Budget Max',
					type: 'line',
					fill: false,
					pointBorderColor: 'rgb(133, 133, 133)',
					pointBackgroundColor: 'rgb(133, 133, 133)',
					borderColor: 'rgb(133, 133, 133)',
					backgroundColor: 'rgb(133, 133, 133)',
				},
			]

			let aggregatedCost = 0
			let aggregatedBudget = productionDuration > 0 ? 0 : projectBudgetAtMargin
			let marginRateSnapshot = 0
			let budgetUsageRateSnapshot = 0
			for (let i = 0; i < totalDays; i++) {
				let dateMoment = earliestDate.clone().add(i, 'days').startOf('day')
				let date = dateMoment.format('YYYY-MM-DD')
				// Add the aggregated cost for the day, if it exists
				let costData = vm.budgetUsageByDay.find((item) => item.date === date)
				if (costData) {
					aggregatedCost += parseFloat(costData.cost)
				}

				// Add the aggregated budget for the day, if it exists
				if (
					dateMoment.isBetween(
						productionStartDate,
						productionEndDate,
						'day',
						'[]',
					)
				) {
					aggregatedBudget += dailyBudget
				}

				// Push values into data layer
				if (!dateMoment.isAfter(today)) {
					vm.budgetUsageChart.data[0].push(aggregatedCost.toFixed(2))
				}
				vm.budgetUsageChart.data[1].push(aggregatedBudget.toFixed(2))
				vm.budgetUsageChart.labels.push(date)

				marginRateSnapshot =
					vm.project.budget > 0
						? ((vm.project.budget - aggregatedCost) / vm.project.budget) * 100
						: 0
				budgetUsageRateSnapshot =
					vm.project.budget > 0
						? (aggregatedCost / projectBudgetAtMargin) * 100
						: 0

				if (date === today.clone().subtract(7, 'days').format('YYYY-MM-DD')) {
					vm.marginRate.weekAgo = marginRateSnapshot
					vm.budgetUsageRate.weekAgo = budgetUsageRateSnapshot
				} else if (date === today.format('YYYY-MM-DD')) {
					vm.marginRate.now = marginRateSnapshot
					vm.budgetUsageRate.now = budgetUsageRateSnapshot
				}
			}

			// If Today is out of range (past the end date), then just reflect the end date.
			if (!vm.budgetUsageRate.now) {
				vm.budgetUsageRate.now = budgetUsageRateSnapshot
			}
			if (!vm.marginRate.now) {
				vm.marginRate.now = marginRateSnapshot
			}
			if (!vm.budgetUsageRate.weekAgo) {
				vm.budgetUsageRate.weekAgo = vm.budgetUsageRate.now
			}
			if (!vm.marginRate.weekAgo) {
				vm.marginRate.weekAgo = vm.marginRate.now
			}

			// Trim the visible chart data
			if (vm.dateStart && vm.dateEnd) {
				let firstIndex = vm.budgetUsageChart.labels.findIndex(
					(date) => date === vm.dateStart.format('YYYY-MM-DD'),
				)
				if (firstIndex < 0) firstIndex = 0
				let lastIndex = vm.budgetUsageChart.labels.findIndex(
					(date) => date === vm.dateEnd.format('YYYY-MM-DD'),
				)
				if (lastIndex < 0) lastIndex = vm.budgetUsageChart.labels.length - 1

				vm.budgetUsageChart.data[0] = vm.budgetUsageChart.data[0].slice(
					firstIndex,
					lastIndex + 1,
				)
				vm.budgetUsageChart.data[1] = vm.budgetUsageChart.data[1].slice(
					firstIndex,
					lastIndex + 1,
				)
				vm.budgetUsageChart.labels = vm.budgetUsageChart.labels.slice(
					firstIndex,
					lastIndex + 1,
				)
			}
		}

		function prepSortableHours() {
			vm.sortableHours = []
			angular.forEach(vm.rawTotals, function (tasks, userId) {
				var userRow = {
					user: vm.usersById[userId],
					totalHours: 0,
					totalCost: 0,
					tasks: [],
				}
				angular.forEach(tasks, function (value, taskId) {
					var taskRow = {
						task: vm.tasksById[taskId],
						hours: value.qty,
						cost: value.cost,
					}
					userRow.totalHours += value.qty
					userRow.totalCost += value.cost
					userRow.tasks.push(taskRow)
				})
				vm.sortableHours.push(userRow)
			})
			return vm.sortableHours
		}

		function goTime(start, end) {
			return vm.$state.go('.', {
				project_id: vm.project.id,
				date_start: start ? start.format('YYYY-MM-DD') : undefined,
				date_end: end ? end.format('YYYY-MM-DD') : undefined,
			})
		}

		function go1Month() {
			return goTime(
				vm.today.clone().subtract(30, 'days').startOf('day'),
				vm.today.clone().startOf('day'),
			)
		}

		function go3Months() {
			return goTime(
				vm.today.clone().subtract(90, 'days').startOf('day'),
				vm.today.clone().startOf('day'),
			)
		}

		function go6Months() {
			return goTime(
				vm.today.clone().subtract(180, 'days').startOf('day'),
				vm.today.clone().startOf('day'),
			)
		}

		function goAllTime() {
			return goTime()
		}

		function goCustom() {
			vm.project = vm.projectAutocomplete.selectedItem
			return goTime(vm.dateStart, vm.dateEnd)
			// return goTime(moment(vm.dateStartField), moment(vm.dateEndField))
		}

		function setupAutocomplete() {
			vm.projectAutocomplete = {}
			vm.projectAutocomplete.selectedItem = vm.project
			vm.projectAutocomplete.querySearch = function (query) {
				var results = []
				if (query) {
					results = vm.projects.filter(
						(function (query) {
							var lowercaseQuery = lowerCase(query)
							return function filterFn(project) {
								return (
									(project.name || '').toLowerCase().indexOf(lowercaseQuery) !=
										-1 ||
									(project.job_code || '')
										.toLowerCase()
										.indexOf(lowercaseQuery) != -1
								)
							}
						})(query),
					)
				} else {
					results = vm.projects
				}
				return results
			}
		}
	}
}

export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		project: '<',
		tasks: '<',
		projects: '<',
		users: '<',
	},
}
