import _ from 'lodash'
import moment from 'moment'
import template from './day-hours-index.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		asUser: '<',
	},
}

/* @ngInject */
function controller(
	$scope,
	$rootScope,
	$state,
	$stateParams,
	$filter,
	$q,
	$mdDialog,
	$mdToast,
	$log,
	authService,
	Favorite,
	Hour,
	OvertimeRequest,
	overtimeRequestsService,
	hoursService,
	orderByFilter,
) {
	var vm = this
	vm.$onInit = function () {
		// API
		vm.focusHour = focusHour
		vm.blurHour = blurHour
		vm.deleteHour = deleteHour
		vm.deleteFavorite = deleteFavorite
		vm.goToEdit = goToEdit
		vm.hoursSum = hoursSum
		vm.hoursService = hoursService
		vm.overtimeRequestsService = overtimeRequestsService
		vm.close = close
		vm.searchHours = searchHours
		vm.sortBy = sortBy
		vm.filterZeros = filterZeros
		vm.hourTrackByKey = hourTrackByKey
		vm.recomputeHoursListDebounced = _.debounce(
			() => recomputeHoursList(),
			0.5e3,
		)

		// Scope Attributes
		vm.isEmulatingUser = vm.asUser.id !== authService.getCurrentUser().id
		vm.today = moment().startOf('day')
		vm.date = (
			$stateParams.date
				? moment($stateParams.date)
				: $scope.incomingDateParam
				? moment($scope.incomingDateParam)
				: moment(vm.today)
		).startOf('day')
		vm.dateLocked = !vm.date.isAfter(vm.asUser.hours_lock_date)
		vm.datePrevious = vm.date.clone().subtract(1, 'days')
		vm.dateNext = vm.date.clone().add(1, 'days')
		vm.dateToday = moment().startOf('day')
		vm.isOnToday = vm.dateToday.isSame(vm.date)
		vm.title = vm.asUser.full_name
		vm.anyMemoExists = false
		vm.searchText = ''
		vm.hideZeros = false

		activate()

		function activate() {
			var bindAllUnbind = OvertimeRequest.bindAll(
				{
					user_id: vm.asUser.id,
					overtime_date: vm.date.format('YYYY-MM-DD'),
				},
				$scope,
				'vm.userRequestsOnDate',
			)

			$scope.$on('$destroy', bindAllUnbind)

			// don't allow entering hours for days in the future
			//if (vm.date.isAfter(vm.today)) {
			//$mdToast.show(
			//$mdToast.simple()
			//.textContent("Fortune teller? No going into the future.")
			//.position('top right')
			//.hideDelay(6000)
			//.theme('error')
			//);
			//$state.go('.', {date: vm.today.format('YYYY-MM-DD')});
			//}

			//vm.dateNext = vm.dateNext.isAfter(vm.today) ? null : vm.dateNext;
			getAllHours()
		}

		function searchHours(hour, index, hours) {
			if (!vm.searchText) return true
			var haystack = [
				hour.project.name,
				hour.project.job_code,
				hour.deliverable && hour.deliverable.display_name,
				hour.version && hour.version.name,
			]
				.filter(Boolean)
				.join(' ')
				.toLocaleLowerCase()
			var needleParts = vm.searchText.toLowerCase().split(' ')
			var match = needleParts.every(function (needlePart) {
				return haystack.indexOf(needlePart) !== -1
			})
			return match
		}

		function filterZeros(hour, index, hours) {
			if (!vm.hideZeros) return true
			else {
				return hour?.quantity > 0
			}
		}

		// function sortHoursToTop(hour) {
		//   return hour.quantity > 0 ? 0 : 1; // sort is ascending
		// }
		function sortBy() {
			vm.hours = orderByFilter(vm.hours, [
				// sortHoursToTop,
				'project.name',
				'deliverable.display_name',
				'version.number',
				'task.name',
			])
		}

		function focusHour(hour) {
			hour.$is_focused = true
			hour.quantity = hour.quantity == '0.0' ? '' : hour.quantity
		}

		function blurHour(hour) {
			function showError() {
				$mdDialog
					.show(
						$mdDialog
							.alert()
							.title('Unexpected Error')
							.textContent('An error occurred while saving that.')
							.ok('Close'),
					)
					.then(function () {
						$state.reload()
					})
			}
			$log.info('blurHour step 1')
			if (hour.$is_pending) {
				showError()
				return
			}
			hour.$is_pending = true
			hour.$is_focused = false
			hour.quantity = !hour.quantity ? '0.0' : hour.quantity
			if (hour.id) {
				Hour.update(hour.id, hour).then(
					function (h) {
						hour.$is_pending = false
						vm.sortBy()
						return h
					},
					function (response) {
						hour.$is_pending = false
						showError()
						return response
					},
				)
			} else if (parseFloat(hour.quantity) > 0.0) {
				Hour.create(hour).then(
					function (h) {
						hour.$is_pending = false
						vm.sortBy()
						var idx = _.indexOf(vm.hours, hour)
						if (idx !== -1) vm.hours[idx] = h
						return h
					},
					function (response) {
						hour.$is_pending = false
						showError()
						return response
					},
				)
			} else {
				if (hour.quantity !== '' && hour.quantity !== '0.0') showError()
				hour.$is_pending = false
			}
		}

		function hourTrackByKey(hour) {
			return `id-${hour.id}:fid-${hour.favorite_id}`
		}

		function deleteHour(hour) {
			$mdDialog
				.show(
					$mdDialog.confirm({
						title: 'Are you sure?',
						textContent:
							'This will delete the ' +
							hour.quantity +
							' hours you entered on ' +
							hour.project.name +
							'.',
						ok: 'Delete',
						cancel: 'Cancel',
						theme: 'md-navbarTheme-theme',
					}),
				)
				.then(function () {
					Hour.destroy(hour.id).then(
						function () {
							$state.reload()
						},
						function () {
							$mdToast.show(
								$mdToast
									.simple()
									.textContent('An unexpected error occurred.')
									.action('Ok')
									.position('top right')
									.hideDelay(6000)
									.theme('error'),
							)
							$state.reload()
						},
					)
				})
		}

		function deleteFavorite(hour) {
			Favorite.destroy(hour.favorite_id).then(
				function () {
					$state.reload()
				},
				function () {
					$mdToast.show(
						$mdToast
							.simple()
							.textContent('An unexpected error occurred.')
							.position('top right')
							.hideDelay(6000)
							.theme('error'),
					)
				},
			)
		}

		function goToEdit(hour) {
			if (vm.dateLocked) {
				return
			}
			if (hour.favorite_id) {
				$state.transitionTo('app.dashboard.user.day.new', {
					as_user: vm.asUser.id,
					favorite_id: hour.favorite_id,
					date: vm.date.format('YYYY-MM-DD'),
				})
			} else {
				$state.transitionTo('app.dashboard.user.day.edit', {
					as_user: vm.asUser.id,
					hour_id: hour.id,
					date: vm.date.format('YYYY-MM-DD'),
				})
			}
		}

		function hoursSum() {
			if (!vm.hours) {
				return 0
			}
			return vm.hours.reduce(function (accum, currentHour) {
				accum += parseFloat(currentHour.quantity) || 0.0
				return accum
			}, 0)
		}

		// private

		function getAllHours() {
			const hourFilter = {
				user_id: vm.asUser.id,
				date: vm.date.format('YYYY-MM-DD'),
			}
			Favorite.bindAll(
				{},
				$scope,
				'vm.rawFavorites',
				vm.recomputeHoursListDebounced,
			)
			Hour.bindAll(
				hourFilter,
				$scope,
				'vm.rawHours',
				vm.recomputeHoursListDebounced,
			)
			Promise.all([
				Favorite.findAll(),
				Hour.findAll(hourFilter, { bypassCache: true }),
			])
		}

		function recomputeHoursList() {
			var safeToRecompute =
				!vm.hours ||
				vm.hours.every((hour) => !hour.$is_pending && !hour.$is_focused)
			if (safeToRecompute) {
				vm.hours = [...(vm.rawHours || [])]
				angular.forEach(vm.hours, function (hour) {
					Hour.loadRelations(hour, [
						'task',
						'project',
						'deliverable',
						'version',
					])
				})
				if (!vm.isEmulatingUser) {
					angular.forEach(vm.rawFavorites, function (fav) {
						var alreadyPresent = false
						_.forEach(vm.hours, function (hour) {
							if (
								fav.project_id == hour.project_id &&
								fav.deliverable_id == hour.deliverable_id &&
								fav.version_id == hour.version_id &&
								fav.task_id == hour.task_id &&
								fav.user_id == hour.user_id
							) {
								alreadyPresent = true
								return false
							}
						})

						if (!alreadyPresent) {
							var hour = Hour.createInstance()
							hour.project_id = fav.project_id
							hour.deliverable_id = fav.deliverable_id
							hour.version_id = fav.version_id
							hour.task_id = fav.task_id
							hour.user_id = fav.user_id
							hour.favorite_id = fav.id
							hour.quantity = ''
							hour.date = vm.date.format('YYYY-MM-DD')
							vm.hours.push(hour)
						}
					})
				}
				_.forEach(vm.hours, function (hour) {
					hour.$track_by = hourTrackByKey(hour)
				})
				vm.anyMemoExists = anyMemosExist()
				vm.sortBy()
				$scope.$apply()
			} else {
				// Hold off and recompute when the user is done editing
				vm.recomputeHoursListDebounced()
			}
		}

		function anyMemosExist() {
			return vm.hours.reduce(function (accum, currentHour) {
				if (currentHour.memo) accum = true
				return accum
			}, false)
		}

		function close() {
			vm.modal = false
		}
	}
}
