/* @ngInject */
export default function expensesService(Expense, Project) {
	var service = {
		create: create,
		expensableClassName: expensableClassName,
	}

	function create(expensable, params) {
		var expensableType = expensableClassName(expensable)
		if (!expensableType)
			throw new Error('argument is not an expensable instance')

		return Expense.create({
			expensable_id: expensable.id,
			expensable_type: expensableType,
			amount: params.amount,
			memo: params.memo,
		})
	}

	function expensableClassName(instance) {
		var expensableModels = Expense.meta.expensableModels
		for (var i = 0; i < expensableModels.length; ++i) {
			if (expensableModels[i].is(instance)) return expensableModels[i].class
		}
		return false
	}

	return service
}
