import template from './teams-index.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($scope, $log, Team) {
	var vm = this
	vm.$onInit = () => {
		//API methods

		//Scope Attributes
		vm.title = 'Teams'
		// vm.tabFilter = 'status_active';
		vm.sortedBy = 'name'
		vm.teams = []

		Team.findAll().then((teams) => {
			Team.bindAll({}, $scope, 'vm.teams')
			return teams
		})
	}
}
