/** @ngInject */
export default function config($stateProvider) {
	$stateProvider
		.state('app.teams', {
			abstract: true,
			url: '/teams',
		})
		.state('app.teams.index', {
			url: '',
			component: 'teamsIndexState',
			resolve: {
				/** @ngInject */
				projects: function (Project) {
					return Project.findAll()
				},
				/** @ngInject */
				users: function (User) {
					return User.findAll()
				},
			},
		})
		.state('app.teams.index.new', {
			url: '/new',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$document,
				$mdDialog,
				users,
				projects,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/teams/teams-new.html',
							controller: 'TeamsNewStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								users,
								projects,
							},
						})
						.finally(function () {
							$state.go('^')
						})
				})
			},
		})
		.state('app.teams.index.show', {
			url: '/:id',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$document,
				$mdDialog,
				$log,
				team,
				users,
				projects,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/teams/teams-show.html',
							controller: 'TeamsShowStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								team,
								users,
								projects,
							},
						})
						.then(
							function () {
								$log.info('Closed normally.')
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			/** @ngInject */
			onExit: function ($mdDialog) {
				$mdDialog.hide()
			},
			resolve: {
				/** @ngInject */
				team: function ($stateParams, Team) {
					return Team.find($stateParams.id)
				},
			},
		})
		.state('app.teams.index.edit', {
			url: '/:id/edit',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$document,
				$mdDialog,
				$log,
				team,
				users,
				projects,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/teams/teams-edit.html',
							controller: 'TeamsEditStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								team,
								users,
								projects,
							},
						})
						.then(
							function () {
								$log.info('Closed normally.')
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			/** @ngInject */
			onExit: function ($mdDialog) {
				$mdDialog.hide()
			},
			resolve: {
				/** @ngInject */
				team: function ($stateParams, Team) {
					return Team.find($stateParams.id, { bypassCache: true })
				},
			},
		})
		.state('app.teams.index.delete', {
			url: '/:id/delete',
			/** @ngInject */
			onEnter: function ($stateParams, $state, $mdDialog, $log, Team, team) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show(
							$mdDialog.confirm({
								title: 'Are you sure?',
								textContent:
									'Are you sure you want to delete ' + team.name + '?',
								ok: 'Delete',
								cancel: 'Cancel',
								theme: 'md-navbarTheme-theme',
							}),
						)
						.then(
							function () {
								team.DSDestroy().finally(function () {
									$state.go('^')
								})
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			resolve: {
				/** @ngInject */
				team: function ($stateParams, Team) {
					return Team.find($stateParams.id)
				},
			},
		})
}
