import moment from 'moment'
/** @ngInject */
export default function hoursService(
	$mdDialog,
	$q,
	$document,
	$state,
	Hour,
	User,
	authService,
) {
	var service = {
		rejectHours: rejectHours,
		approveHours: approveHours,
		chartTaskHours: chartTaskHours,
		chartJobHours: chartJobHours,
		getUserWeekHours: getUserWeekHours,
		userDateIsLocked: userDateIsLocked,
		userDateIsReviewable: userDateIsReviewable,
		userHasSubmitted: userHasSubmitted,
		userHasResubmitted: userHasResubmitted,
		userHasRejection: userHasRejection,
		unlockUserDate: unlockUserDate,
		submitHours: submitHours,
	}

	function rejectHours(rejectionDate, rejectionNote, user, cb) {
		if (!moment.isMoment(rejectionDate)) rejectionDate = moment(rejectionDate)
		Hour.reject({
			params: {
				date: rejectionDate.clone().subtract(1, 'day').format('YYYY-MM-DD'),
				user_id: user.id,
				hours_rejected_by_user_id: authService.getCurrentUser().id,
				hours_rejected_note: rejectionNote,
			},
		})
			.then(function (response) {
				if (response && response.data) User.inject(response.data)
			})
			.finally(function () {
				if (angular.isFunction(cb)) cb()
			})
	}

	function approveHours(approvalDate, user, cb) {
		if (!moment.isMoment(approvalDate)) approvalDate = moment(approvalDate)
		Hour.approve({
			params: {
				user_id: user.id,
				date: approvalDate.format('YYYY-MM-DD'),
			},
		})
			.then(function (response) {
				if (response && response.data) User.inject(response.data)
			})
			.finally(function () {
				if (angular.isFunction(cb)) cb()
			})
	}

	function chartTaskHours(hours) {
		var taskChart = {
			labels: [],
			data: [],
			totlaHours: 0,
			map: [],
		}
		angular.forEach(hours, function (hour) {
			var n = hour.task.name
			var q = hour.quantity
			var nIndex = taskChart.labels.indexOf(n)
			if (nIndex > -1)
				taskChart.data[nIndex] =
					parseFloat(taskChart.data[nIndex]) + parseFloat(q)
			else {
				taskChart.labels.push(n)
				taskChart.data.push(q)
			}
			taskChart.totalHours += parseFloat(q)
			taskChart.map.push({ name: n, value: q })
		})
		return taskChart
	}

	function chartJobHours(hours) {
		var jobChart = {
			labels: [],
			data: [],
			map: [],
			totalHours: 0,
		}
		angular.forEach(hours, function (hour) {
			var n = hour.project.job_code
			var q = hour.quantity
			var nIndex = jobChart.labels.indexOf(n)
			if (nIndex > -1)
				jobChart.data[nIndex] =
					parseFloat(jobChart.data[nIndex]) + parseFloat(q)
			else {
				jobChart.labels.push(n)
				jobChart.data.push(q)
			}
			jobChart.totalHours += parseFloat(q)
			jobChart.map.push({ name: n, value: q })
		})
		return jobChart
	}

	function getUserWeekHours(user, date) {
		var relations = []
		return Hour.findAll(
			{
				user_id: user.id,
				date_start: date.format('YYYY-MM-DD'),
				date_end: moment(date).add(1, 'week').format('YYYY-MM-DD'),
			},
			{ bypassCache: true },
		).then(function (hours) {
			angular.forEach(hours, function (hour) {
				relations.push(
					Hour.loadRelations(hour, [
						'task',
						'project',
						'deliverable',
						'version',
					]),
				)
			})

			return $q.all(relations).then(function () {
				return hours
			})
		})
	}

	function userDateIsLocked(user, date) {
		return date.isSameOrBefore(user.hours_lock_date)
	}

	function userDateIsReviewable(user, date) {
		return date.isBetween(
			user.hours_approved_date,
			user.hours_lock_date,
			'day',
			'(]',
		)
	}

	function userHasSubmitted(user) {
		return (
			!user.hours_rejected_note &&
			userDateIsReviewable(user, moment(user.hours_lock_date))
		)
	}

	function userHasResubmitted(user) {
		return (
			user.hours_rejected_note &&
			userDateIsReviewable(user, moment(user.hours_lock_date))
		)
	}

	function userHasRejection(user) {
		return (
			user.hours_rejected_note &&
			moment(user.hours_lock_date).isSame(user.hours_approved_date)
		)
	}

	function submitHours(user, date) {
		Hour.findAll({
			user_id: user.id,
			date_start: moment(date).add(1, 'day').format('YYYY-MM-DD'),
			date_end: moment().format('YYYY-MM-DD'),
		}).then(
			function (hours) {
				if (!hours.length) {
					_lockHours(user, date)
					return
				} else {
					var lastDate = moment(
						Hour.filter({ user_id: user.id })
							.filter(function (h) {
								return parseFloat(h.quantity) > 0
							})
							.sort(function (a, b) {
								return new Date(b.date).getTime() - new Date(a.date).getTime()
							})[0].date,
					)

					var parentEl = angular.element($document.body)
					$mdDialog.show({
						parent: parentEl,
						template:
							'<md-dialog aria-label="Hours dialog" class="max-width-1/2">' +
							'  <md-dialog-content class="padded-more md-subhead">' +
							'Do you want to submit hours only for ' +
							date.format('dddd, MMMM Do YYYY') +
							', or for all hours entered up to and including ' +
							lastDate.format('dddd, MMMM Do YYYY') +
							'?' +
							'  </md-dialog-content>' +
							'  <md-dialog-actions>' +
							'    <md-button ng-click="ctrl.closeDialog()" class="md-primary">' +
							'      Cancel' +
							'    </md-button>' +
							'    <md-button ng-click="ctrl.justOneDay()" class="md-primary">' +
							'      Just One Day' +
							'    </md-button>' +
							'    <md-button ng-click="ctrl.allHours()" class="md-primary">' +
							'      All Hours' +
							'    </md-button>' +
							'  </md-dialog-actions>' +
							'</md-dialog>',
						/** @ngInject */
						controller: function ($mdDialog) {
							this.closeDialog = $mdDialog.hide.bind(this)
							this.justOneDay = _lockHours.bind(this, user, date)
							this.allHours = _lockHours.bind(this, user, lastDate)
						},
						controllerAs: 'ctrl',
					})
				}
			},
			function () {
				$state.reload()
			},
		)
	}

	function unlockUserDate(user, date) {
		$mdDialog
			.show(
				$mdDialog.confirm({
					title: 'Are you sure?',
					textContent:
						'This will allow ' +
						user.full_name +
						' to change ALL HOURS starting on ' +
						date.format('YYYY-MM-DD') +
						'?',
					ok: 'Submit',
					cancel: 'Cancel',
					theme: 'md-navbarTheme-theme',
				}),
			)
			.then(
				function () {
					Hour.unlock({
						params: {
							date: date.format('YYYY-MM-DD'),
							user_id: user.id,
						},
					}).then(async function (response) {
						if (response && response.data) {
							await User.find(response.data.id, { bypassCache: true })
							$state.reload()
						}
					})
				},
				function () {},
			)
	}

	function _lockHours(user, date) {
		date = moment(date)
		Hour.lock({
			params: {
				user_id: user.id,
				date: date.format('YYYY-MM-DD'),
			},
		}).then(
			async function ({ data: user }) {
				const currentUser = authService.getCurrentUser()

				if (currentUser?.id === user?.id) {
					await authService.refreshCurrentUser()
				} else {
					await User.find(user.id, { bypassCache: true })
				}

				$state.reload()
				$mdDialog.hide()
			},
			function (err) {
				if (err.data && err.data.errors) {
					var errorString = "We couldn't save for the following reasons:<br />"
					angular.forEach(err.data.errors, function (value, key) {
						errorString += '<div class="api-errors">'
						errorString += '<b class="field-name all-caps">' + key + '</b><ul>'
						if (!angular.isArray(value)) {
							value = [value]
						}
						angular.forEach(value, function (value, key) {
							errorString += '<li>' + value + '</li>'
						})
						errorString += '</ul></div>'
					})
				}

				$mdDialog.show(
					$mdDialog.alert({
						title: 'ERROR: COULD NOT SAVE',
						htmlContent: errorString,
						ok: 'OK',
						theme: 'md-navbarTheme-theme',
					}),
				)
				return $q.reject(err)
			},
		)
	}

	return service
}
