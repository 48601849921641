import moment from 'moment'
import template from './hours-audit.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		user: '<',
	},
}

/* @ngInject */
function controller($scope, $state, $stateParams, hoursService) {
	var vm = this
	vm.$onInit = () => {
		// API
		vm.hoursService = hoursService
		vm.exit = exit

		// Scope Attributes
		vm.auditDate = moment($stateParams.audit_date)

		function exit() {
			$state.go('^')
		}
	}
}
