import inject from 'inject'
import _, { lowerCase } from 'lodash'
import template from './users-new.html'
@inject(
	'$scope',
	'$state',
	'$mdDialog',
	'$log',
	'Task',
	'Team',
	'Department',
	'User',
)
class controller {
	$onInit() {
		this.user = this.User.createInstance().cloneFields()
		this.statusOptions = this.User.meta.statusLabels
		this.roleOptions = this.User.meta.roleLabels
		this.rateTypeOptions = this.User.meta.rateTypeLabels

		this.user.status = this.User.meta.statuses.active
		this.user.role = this.User.meta.roles.user
		this.user.remindable = true
		this.user.requires_timesheet_approval = true
		this.user.teams = []
		this.user.tasks = []
		this.userSupervisors = []

		this.User.findAll().then((users) => {
			this.users = users
		})
		this.Team.findAll().then((teams) => {
			this.teams = teams
		})
		this.Task.findAll().then((tasks) => {
			this.tasks = tasks
		})
		this.Department.findAll().then((departments) => {
			this.departments = departments
		})
	}

	cancel() {
		this.close()
	}

	close() {
		this.$mdDialog.cancel()
	}

	querySearchTasks(query) {
		var results = []
		if (query) {
			results = this.tasks.filter(
				((query) => {
					var lowercaseQuery = lowerCase(query)
					return (item) => {
						return (
							item.name.toLowerCase().indexOf(lowercaseQuery) != -1 &&
							!this.userHasTask(item)
						)
					}
				})(query),
			)
		}
		return results
	}

	querySearchTeams(query) {
		var results = []
		if (query) {
			results = this.teams.filter(
				((query) => {
					var lowercaseQuery = lowerCase(query)
					return (item) => {
						return (
							item.name.toLowerCase().indexOf(lowercaseQuery) != -1 &&
							!this.userHasTeam(item)
						)
					}
				})(query),
			)
		}
		return results
	}

	querySearchSupervisors(query) {
		var results = []
		if (query) {
			results = this.users.filter(
				((query) => {
					var lowercaseQuery = lowerCase(query)
					return (user) => {
						return (
							user.full_name.toLowerCase().indexOf(lowercaseQuery) != -1 &&
							!this.userHasSupervisor(user)
						)
					}
				})(query),
			)
		}
		return results
	}

	userHasTask(item) {
		if (_.find(this.user.tasks, { id: item.id })) return true
		return false
	}

	userHasTeam(item) {
		if (_.find(this.user.teams, { id: item.id })) return true
		return false
	}

	userHasSupervisor(item) {
		if (_.find(this.user.supervisor_ids, { id: item.id })) return true
		return false
	}

	save() {
		if (this.savePending) return
		this.savePending = true
		this.user.supervisor_ids = this.userSupervisors.map((supervisor) => {
			return supervisor.id
		})
		this.User.create(this.user)
			.then((_user) => {
				this.close()
			})
			.catch((err) => {
				this.$log.warn(err)
			})
			.finally(() => {
				this.savePending = false
			})
	}
}

export default {
	controller,
	controllerAs: 'vm',
	template,
}
