import template from './dashboard-admin.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller(Project) {
	var vm = this
	vm.$onInit = () => {
		// API

		// Scope attributes
		vm.title = 'Dashboard'

		activate()
		function activate() {
			Project.findAll({
				status: Project.meta.statuses.active,
			}).then(function (projects) {
				vm.projects = projects
			})
		}
	}
}
