import { clone as _clone } from 'lodash'
import moment from 'moment'
Team.autoInjectAs = 'Team'

/* @ngInject */
export default function Team(DS) {
	return DS.defineResource({
		name: 'team',
		endpoint: 'teams',
		computed: {
			created_at_short: ['created_at', createdAtShort],
		},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					name: this.name,
					user_ids: _clone(this.user_ids),
					project_ids: _clone(this.project_ids),
				}
			},
		},
		relations: {
			hasMany: {
				user: {
					localField: 'users',
					localKeys: 'user_ids',
				},
				project: {
					localField: 'projects',
					localKeys: 'project_ids',
				},
			},
		},
	})

	function createdAtDate(created_at) {
		return new Date(created_at)
	}

	function createdAtShort(created_at) {
		return moment(created_at).format('MM/DD/YYYY')
	}
}

/* @ngInject */
function runBlockTeam(Team) {}
