import template from './versions-index.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller(
	$scope,
	$log,
	Deliverable,
	deliverable,
	Version,
	$window,
	$state,
	$stateParams,
	$mdDialog,
) {
	var vm = this
	vm.$onInit = () => {
		// API
		vm.changeDeliverable = changeDeliverable

		// Scope Attributes
		vm.title = 'Versions'
		vm.sortedBy = 'name'
		vm.activeDeliverable = deliverable.id
		vm.dialog = {}
		vm.versions = []
		vm.deliverables = []
		vm.deliverable = deliverable
		vm.tabFilter = 'status_active'

		Deliverable.findAll({ project_id: deliverable.project_id }).then(
			(deliverables) => {
				vm.deliverables = deliverables
				return deliverables
			},
		)

		const versionsFilter = { deliverable_id: deliverable.id }
		Version.findAll(versionsFilter).then((versions) => {
			Version.bindAll(versionsFilter, $scope, 'vm.versions')
			return versions
		})

		function changeDeliverable(to) {
			$state.go('.', { deliverable_id: to })
		}
	}
}
