/** @ngInject */
export default function config($stateProvider) {
	$stateProvider
		.state('app.deliverables', {
			abstract: true,
			url: '/deliverables',
		})
		.state('app.deliverables.index', {
			url: '/:project_id',
			templateUrl: 'app/states/deliverables/deliverables-index.html',
			controller: 'DeliverablesIndexStateController',
			controllerAs: 'vm',
		})
		.state('app.deliverables.index.new', {
			url: '/new/',
			/** @ngInject */
			onEnter: function ($stateParams, $state, $document, $mdDialog) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/deliverables/deliverables-new.html',
							controller: 'DeliverablesNewStateController',
							bindToController: true,
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
						})
						.finally(function () {
							$state.go('^')
						})
				})
			},
		})
		.state('app.deliverables.index.edit', {
			url: '/edit/:id',
			/** @ngInject */
			onEnter: function (
				Deliverable,
				$stateParams,
				$state,
				$document,
				$mdDialog,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							resolve: {
								deliverable: function () {
									return Deliverable.find($stateParams.id)
								},
							},
							templateUrl: 'app/states/deliverables/deliverables-edit.html',
							controller: 'DeliverablesEditStateController',
							bindToController: true,
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
						})
						.finally(function () {
							$state.go('^')
						})
				})
			},
		})
		.state('app.deliverables.index.show', {
			url: '/:id',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$document,
				$mdDialog,
				$log,
				deliverable,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/deliverables/deliverables-show.html',
							controller: 'DeliverablesShowStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								deliverable: deliverable,
							},
						})
						.then(
							function () {
								$log.info('Closed normally.')
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			/** @ngInject */
			onExit: function ($mdDialog) {
				$mdDialog.hide()
			},
			resolve: {
				/** @ngInject */
				deliverable: function ($stateParams, Deliverable) {
					return Deliverable.find($stateParams.id).then(function (deliverable) {
						return Deliverable.loadRelations(deliverable, [
							'project',
							'version',
						])
					})
				},
			},
		})
		.state('app.deliverables.index.delete', {
			url: '/:id/delete',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$mdDialog,
				$log,
				Deliverable,
				deliverable,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show(
							$mdDialog.confirm({
								title: 'Are you sure?',
								textContent:
									'Are you sure you want to delete ' +
									deliverable.display_name +
									'?',
								ok: 'Delete',
								cancel: 'Cancel',
								theme: 'md-navbarTheme-theme',
							}),
						)
						.then(
							function () {
								deliverable.DSDestroy().finally(function () {
									$state.go('^')
								})
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			resolve: {
				/** @ngInject */
				deliverable: function ($stateParams, Deliverable) {
					return Deliverable.find($stateParams.id)
				},
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.manager)
				},
			},
		})
}
