import template from './projects-index.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller(
	$scope,
	$log,
	Client,
	Project,
	$window,
	$state,
	$stateParams,
	$mdDialog,
) {
	var vm = this
	vm.$onInit = () => {
		// API
		vm.Project = Project
		vm.gotoProject = gotoProject
		vm.projectNameFilter = projectNameFilter

		// Scope Attributes
		vm.title = 'Projects'
		vm.sortedBy = 'name_sortable'
		vm.dialog = {}
		vm.projects = []
		vm.tabFilter = Project.meta.statuses.active
		vm.projectNameSearch = null
		vm.projectNameSearchTerms = []

		$scope.$watch('vm.projectNameSearch', () => {
			if (vm.projectNameSearch) {
				vm.projectNameSearchTerms = vm.projectNameSearch
					.toLowerCase()
					.split(' ')
			} else {
				vm.projectNameSearchTerms = []
			}
		})

		$scope.$watch('vm.tabFilter', getAllProjects)

		getAllProjects()

		function getAllProjects() {
			const projectFilters = {
				client_id: $stateParams.client_id,
				status: vm.tabFilter,
			}
			Project.findAll(projectFilters).then(function (projects) {
				vm.projects = projects
			})

			vm.unbindProjectBindAll?.()
			vm.unbindProjectBindAll = Project.bindAll(
				projectFilters,
				$scope,
				'vm.projects',
			)
		}

		function gotoProject(project) {
			$state.go('app.projects.index.show', { id: project.id })
		}

		function projectNameFilter(item, index, array) {
			if (vm.projectNameSearchTerms && vm.projectNameSearchTerms.length > 0) {
				const projectSearchString =
					`${item.name} ${item.job_code} ${item.job_number}`.toLowerCase()
				return vm.projectNameSearchTerms.every((term) => {
					const regex = new RegExp(`\\b${term}`)
					return regex.test(projectSearchString)
				})
			} else {
				return true
			}
		}
	}
}
