const _temp0 = require("../modal.html");
const _temp1 = require("../auth/google-signin-template.html");

const _temp2 = require("../autocomplete/autocomplete-base-directive.html");

const _temp3 = require("../expenses/expenses-directive.html");

const _temp4 = require("../forms/errors.html");

const _temp5 = require("../helpers/helper-directive.html");

const _temp6 = require("../hours/day-tags-directive.html");

const _temp7 = require("../projects/project-spend-chip-directive.html");
const _temp8 = require("../projects/project-thermometer-directive.html");


const _temp9 = require("../../layouts/base.html");
const _temp10 = require("../../layouts/one-column.html");
const _temp11 = require("../../layouts/two-column.html");

const _temp12 = require("../../states/clients/clients-edit.html");
const _temp13 = require("../../states/clients/clients-index.html");
const _temp14 = require("../../states/clients/clients-new.html");
const _temp15 = require("../../states/clients/clients-show.html");

const _temp16 = require("../../states/dashboard/dashboard-admin.html");
const _temp17 = require("../../states/dashboard/dashboard-manager.html");
const _temp18 = require("../../states/dashboard/dashboard.html");
const _temp19 = require("../../states/dashboard/day/day-hours-form.html");
const _temp20 = require("../../states/dashboard/day/day-hours-index.html");

const _temp21 = require("../../states/dashboard/week/week-hours-index.html");


const _temp22 = require("../../states/deliverables/deliverables-edit.html");
const _temp23 = require("../../states/deliverables/deliverables-index.html");
const _temp24 = require("../../states/deliverables/deliverables-new.html");
const _temp25 = require("../../states/deliverables/deliverables-show.html");

const _temp26 = require("../../states/departments/departments-edit.html");
const _temp27 = require("../../states/departments/departments-index.html");
const _temp28 = require("../../states/departments/departments-new.html");
const _temp29 = require("../../states/departments/departments-show.html");

const _temp30 = require("../../states/guest/guest.html");

const _temp31 = require("../../states/logout/logout.html");

const _temp32 = require("../../states/me/me-profile.html");

const _temp33 = require("../../states/projects/projects-edit.html");
const _temp34 = require("../../states/projects/projects-index.html");
const _temp35 = require("../../states/projects/projects-new.html");
const _temp36 = require("../../states/projects/projects-show.html");

const _temp37 = require("../../states/properties/properties-edit.html");
const _temp38 = require("../../states/properties/properties-index.html");
const _temp39 = require("../../states/properties/properties-new.html");
const _temp40 = require("../../states/properties/properties-show.html");

const _temp41 = require("../../states/reports/hours-audit.html");
const _temp42 = require("../../states/reports/overtime-request-audit.html");
const _temp43 = require("../../states/reports/reports-download-hours.html");
const _temp44 = require("../../states/reports/reports-hours-user.html");
const _temp45 = require("../../states/reports/reports-hours-week.html");
const _temp46 = require("../../states/reports/reports-summary-project-edit.html");
const _temp47 = require("../../states/reports/reports-summary-project-index.html");
const _temp48 = require("../../states/reports/reports-summary-project-view.html");
const _temp49 = require("../../states/reports/reports-summary-user.html");

const _temp50 = require("../../states/tasks/tasks-edit.html");
const _temp51 = require("../../states/tasks/tasks-index.html");
const _temp52 = require("../../states/tasks/tasks-new.html");
const _temp53 = require("../../states/tasks/tasks-show.html");

const _temp54 = require("../../states/teams/teams-edit.html");
const _temp55 = require("../../states/teams/teams-index.html");
const _temp56 = require("../../states/teams/teams-new.html");
const _temp57 = require("../../states/teams/teams-show.html");

const _temp58 = require("../../states/users/users-edit.html");
const _temp59 = require("../../states/users/users-index.html");
const _temp60 = require("../../states/users/users-new.html");
const _temp61 = require("../../states/users/users-show.html");

const _temp62 = require("../../states/versions/versions-edit.html");
const _temp63 = require("../../states/versions/versions-index.html");
const _temp64 = require("../../states/versions/versions-new.html");
const _temp65 = require("../../states/versions/versions-show.html");


module.exports = {
  "components":   {
    "modal": _temp0,
    "auth":     {
      "google-signin-template": _temp1
    },
    "autocomplete":     {
      "autocomplete-base-directive": _temp2
    },
    "expenses":     {
      "expenses-directive": _temp3
    },
    "forms":     {
      "errors": _temp4
    },
    "helpers":     {
      "helper-directive": _temp5
    },
    "hours":     {
      "day-tags-directive": _temp6
    },
    "projects":     {
      "project-spend-chip-directive": _temp7,
      "project-thermometer-directive": _temp8
    }
  },
  "layouts":   {
    "base": _temp9,
    "one-column": _temp10,
    "two-column": _temp11
  },
  "states":   {
    "clients":     {
      "clients-edit": _temp12,
      "clients-index": _temp13,
      "clients-new": _temp14,
      "clients-show": _temp15
    },
    "dashboard":     {
      "dashboard-admin": _temp16,
      "dashboard-manager": _temp17,
      "dashboard": _temp18,
      "day":       {
        "day-hours-form": _temp19,
        "day-hours-index": _temp20
      },
      "week":       {
        "week-hours-index": _temp21
      }
    },
    "deliverables":     {
      "deliverables-edit": _temp22,
      "deliverables-index": _temp23,
      "deliverables-new": _temp24,
      "deliverables-show": _temp25
    },
    "departments":     {
      "departments-edit": _temp26,
      "departments-index": _temp27,
      "departments-new": _temp28,
      "departments-show": _temp29
    },
    "guest":     {
      "guest": _temp30
    },
    "logout":     {
      "logout": _temp31
    },
    "me":     {
      "me-profile": _temp32
    },
    "projects":     {
      "projects-edit": _temp33,
      "projects-index": _temp34,
      "projects-new": _temp35,
      "projects-show": _temp36
    },
    "properties":     {
      "properties-edit": _temp37,
      "properties-index": _temp38,
      "properties-new": _temp39,
      "properties-show": _temp40
    },
    "reports":     {
      "hours-audit": _temp41,
      "overtime-request-audit": _temp42,
      "reports-download-hours": _temp43,
      "reports-hours-user": _temp44,
      "reports-hours-week": _temp45,
      "reports-summary-project-edit": _temp46,
      "reports-summary-project-index": _temp47,
      "reports-summary-project-view": _temp48,
      "reports-summary-user": _temp49
    },
    "tasks":     {
      "tasks-edit": _temp50,
      "tasks-index": _temp51,
      "tasks-new": _temp52,
      "tasks-show": _temp53
    },
    "teams":     {
      "teams-edit": _temp54,
      "teams-index": _temp55,
      "teams-new": _temp56,
      "teams-show": _temp57
    },
    "users":     {
      "users-edit": _temp58,
      "users-index": _temp59,
      "users-new": _temp60,
      "users-show": _temp61
    },
    "versions":     {
      "versions-edit": _temp62,
      "versions-index": _temp63,
      "versions-new": _temp64,
      "versions-show": _temp65
    }
  }
}