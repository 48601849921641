import template from './departments-index.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($scope, $log, Department) {
	var vm = this
	vm.$onInit = () => {
		//API methods

		//Scope Attributes
		vm.title = 'Departments'
		// vm.tabFilter = 'status_active';
		vm.sortedBy = 'name'
		vm.departments = []

		Department.findAll().then((departments) => {
			Department.bindAll({}, $scope, 'vm.departments')
			return departments
		})
	}
}
