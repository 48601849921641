/** @ngInject */
export default function config($stateProvider) {
	$stateProvider
		.state('app.clients', {
			abstract: true,
			url: '/clients',
		})
		.state('app.clients.index', {
			url: '',
			component: 'clientsIndexState',
		})
		.state('app.clients.index.new', {
			url: '/new',
			/** @ngInject */
			onEnter: function ($stateParams, $state, $document, $mdDialog) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/clients/clients-new.html',
							controller: 'ClientsNewStateController',
							bindToController: true,
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
						})
						.finally(function () {
							$state.go('^')
						})
				})
			},
			resolve: {
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.admin)
				},
			},
		})
		.state('app.clients.index.show', {
			url: '/:id',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$document,
				$mdDialog,
				$log,
				client,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/clients/clients-show.html',
							controller: 'ClientsShowStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								client: client,
							},
						})
						.then(
							function () {
								$log.info('Closed normally.')
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			/** @ngInject */
			onExit: function ($mdDialog) {
				$mdDialog.hide()
			},
			resolve: {
				/** @ngInject */
				client: function ($stateParams, Client) {
					return Client.find($stateParams.id).then(function (client) {
						return Client.loadRelations(client, 'property')
					})
				},
			},
		})
		.state('app.clients.index.edit', {
			url: '/:id/edit',
			/** @ngInject */
			onEnter: function (
				$stateParams,
				$state,
				$document,
				$mdDialog,
				$log,
				client,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show({
							templateUrl: 'app/states/clients/clients-edit.html',
							controller: 'ClientsEditStateController',
							controllerAs: 'vm',
							clickOutsideToClose: true,
							parent: angular.element($document.body),
							bindToController: true,
							locals: {
								client: client,
							},
						})
						.then(
							function () {
								$log.info('Closed normally.')
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			/** @ngInject */
			onExit: function ($mdDialog) {
				$mdDialog.hide()
			},
			resolve: {
				/** @ngInject */
				client: function ($stateParams, Client) {
					return Client.find($stateParams.id, { bypassCache: true })
				},
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.admin)
				},
			},
		})
		.state('app.clients.index.delete', {
			url: '/:id/delete',
			onEnter: function (
				$stateParams,
				$state,
				$mdDialog,
				$log,
				Client,
				client,
			) {
				$state.transition.promise.then(() => {
					$mdDialog
						.show(
							$mdDialog.confirm({
								title: 'Are you sure?',
								textContent:
									'Are you sure you want to delete ' + client.name + '?',
								ok: 'Delete',
								cancel: 'Cancel',
								theme: 'md-navbarTheme-theme',
							}),
						)
						.then(
							function () {
								client.DSDestroy().finally(function () {
									$state.go('^')
								})
							},
							function () {
								$state.go('^')
							},
						)
				})
			},
			resolve: {
				/** @ngInject */
				client: function ($stateParams, Client) {
					return Client.find($stateParams.id)
				},
				/** @ngInject */
				isAuthorized: function (authService) {
					return authService.checkAccessPromise(authService.roles.admin)
				},
			},
		})
}
