import template from './clients-new.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($scope, $state, $mdDialog, $log, Client) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.save = save

		//Scope attributes
		vm.client = Client.createInstance()
		vm.statusOptions = Client.meta.statusLabels

		activate()
		function activate() {
			vm.client.status = Client.meta.statuses.active
		}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function save() {
			Client.create(vm.client).then(function (client) {
				close()
			})
		}
	}
}
