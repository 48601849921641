/** @ngInject */
export default function ExceptionNotifier($q, $rootScope) {
	var service = {
		responseError: responseError,
	}

	function responseError(response) {
		$rootScope.$broadcast('httpException', response)
		return $q.reject(response)
	}

	return service
}
