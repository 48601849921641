import moment from 'moment'
import template from './reports-download-hours.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller(
	$scope,
	$state,
	$mdDialog,
	$log,
	downloadJs,

	Hour,
	Project,
	User,
	dateStart,
	dateEnd,
) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.download = download

		//Scope attributes
		vm.report = {}
		vm.Project = Project
		vm.User = User
		vm.loading = true

		activate()
		function activate() {
			vm.report.date_start = dateStart.toDate()
			vm.report.date_end = dateEnd.toDate()
			vm.users = User.filter({ status: User.meta.statuses.active })

			Project.findAll().then(function (projects) {
				vm.projects = projects
				vm.loading = false
				return projects
			})
		}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function download() {
			if (vm.pending) return
			vm.pending = true
			Hour.csv({
				params: {
					date_start: moment(vm.report.date_start).format('YYYY-MM-DD'),
					date_end: moment(vm.report.date_end)
						.clone()
						.add(1, 'days')
						.format('YYYY-MM-DD'),
					user_id: vm.report.user_id,
					project_id: vm.report.project_id,
				},
			}).then(function (response) {
				downloadJs.download(response.data, 'hours-report.csv', 'text/csv')
				close()
				vm.pending = false
			})
		}
	}
}
