import template from './clients-index.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($scope, Client) {
	var vm = this
	vm.$onInit = () => {
		//API methods

		//Scope Attributes
		vm.title = 'Clients'
		vm.tabFilter = 'status_active'
		vm.sortedBy = 'name'
		vm.clients = []

		Client.findAll().then((clients) => {
			Client.bindAll({}, $scope, 'vm.clients')
			return clients
		})
	}
}
