/**
 * based on https://github.com/sagiegurari/angular-web-notification/
 */
/** @ngInject */
export default function webNotification($timeout) {
	var service = {
		isEnabled: isEnabled,
		sendNotification: sendNotification,
		checkAccess: checkAccess,
	}

	activate()
	function activate() {}

	function isEnabled() {
		var enabled

		if (!('Notification' in window)) {
			enabled = false
		} else if (Notification.permission === 'granted') {
			enabled = true
		} else if (Notification.permission !== 'denied') {
			Notification.requestPermission(function (permission) {
				// If the user accepts, let's create a notification
				if (permission === 'granted') {
					service.sendNotification(
						'Notifications enabled!',
						'Thank you for enabling notifications! :)',
					)
				}
			})
		}

		return enabled
	}

	function sendNotification(title, message) {
		return new Notification(title, {
			body: message,
			icon: '/assets/img/favicon-96x96.png',
			sound: '/assets/sfx/beep.mp3',
		})
		// auto-close notifications
		// $timeout(function(){
		//   if (notification) notification.close();
		// }, 1000);
	}

	function checkAccess() {
		return service.isEnabled()
	}

	return service
}
