import template from './users-show.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller($mdDialog, $log, user) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel

		//Scope attributes
		vm.user = user

		activate()
		function activate() {
			vm.userSupervisors = []
			vm.user.getSupervisors().then(function (supervisors) {
				vm.userSupervisors = supervisors
			})
			vm.userReportees = []
			user.getReportees().then(function (reportees) {
				vm.userReportees = reportees
			})
		}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}
	}
}
