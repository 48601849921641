import template from './deliverables-edit.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
}

/* @ngInject */
function controller(
	$scope,
	$state,
	$mdDialog,
	$log,
	Deliverable,
	deliverable,
	deliverableDescriptions,
	Property,
) {
	var vm = this
	vm.$onInit = () => {
		//API methods
		vm.cancel = cancel
		vm.save = save
		vm.searchDescriptions = searchDescriptions

		//Scope attributes
		vm.title = deliverable.display_name
		vm.deliverable = deliverable.cloneFields()
		vm.statusOptions = Deliverable.meta.statusLabels

		activate()
		function activate() {
			Property.findAll().then(function (properties) {
				vm.properties = properties
			})
		}

		function cancel() {
			close()
		}

		function close() {
			$mdDialog.cancel()
		}

		function save() {
			deliverable.DSUpdate(vm.deliverable).then(function (deliverable) {
				close()
			})
		}

		function searchDescriptions(searchText) {
			searchText = (searchText || '').toLowerCase()
			return deliverableDescriptions.filter(function (i) {
				return i.toLowerCase().indexOf(searchText) !== -1
			})
		}
	}
}
