import moment from 'moment'
Deliverable.autoInjectAs = 'Deliverable'

/* @ngInject */
export default function Deliverable(DS) {
	var statuses = {
		active: 'status_active',
		archived: 'status_archived',
	}
	var statusLabels = {
		status_active: 'Active',
		status_archived: 'Archived',
	}

	return DS.defineResource({
		name: 'deliverable',
		endpoint: 'deliverables',
		computed: {
			created_at_short: ['created_at', createdAtShort],
			status_readable: ['status', readableStatus],
		},
		meta: {
			statuses: statuses,
			statusLabels: statusLabels,
		},
		methods: {
			cloneFields: function () {
				return {
					id: this.id,
					project_id: this.project_id,
					description: this.description,
					number: this.number,
					name: this.name,
					status: this.status,
				}
			},
		},
		relations: {
			belongsTo: {
				project: [
					{
						localField: 'project',
						localKey: 'project_id',
					},
				],
			},
			hasMany: {
				version: [
					{
						localField: 'versions',
						foreignKey: 'deliverable_id',
					},
				],
			},
		},
		actions: {
			next_number: {
				method: 'GET',
			},
		},
	})

	function createdAtDate(created_at) {
		return new Date(created_at)
	}

	function createdAtShort(created_at) {
		return moment(created_at).format('MM/DD/YYYY')
	}

	function readableStatus(status) {
		return statusLabels.hasOwnProperty(status) ? statusLabels[status] : ''
	}
}

/* @ngInject */
function runBlockDeliverable(Deliverable) {}
