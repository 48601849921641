import template from './overtime-request-audit.html'
export default {
	controller,
	controllerAs: 'vm',
	template,
	bindings: {
		otr: '<',
	},
}

/* @ngInject */
function controller(
	$scope,
	$timeout,
	$state,
	$stateParams,
	$mdDialog,
	OvertimeRequest,
	overtimeRequestsService,
) {
	var vm = this
	vm.$onInit = () => {
		// API
		vm.OvertimeRequest = OvertimeRequest
		vm.overtimeRequestsService = overtimeRequestsService
		vm.approve = approve
		vm.reject = reject
		vm.exit = exit

		// Guard clause for non-pending OTRs
		if (
			vm.otr.status != OvertimeRequest.meta.statuses.pending &&
			($stateParams.auto_approve || $stateParams.disapprove)
		) {
			exit()
			return $mdDialog.show(
				$mdDialog
					.alert()
					.title('Error')
					.textContent(
						'Overtime Request for ' +
							vm.otr.user.full_name +
							' on ' +
							vm.otr.overtime_date +
							' has already been reviewed',
					)
					.ok('Close'),
			)
		}

		if ($stateParams.auto_approve) {
			approve().then(function () {
				$mdDialog.show(
					$mdDialog
						.alert()
						.title('Approved')
						.textContent(
							'Overtime Request for ' +
								vm.otr.overtime_date +
								' by ' +
								vm.otr.user.full_name +
								' was approved',
						)
						.ok('close'),
				)
			})
		} else if ($stateParams.disapprove) {
			$timeout(function () {
				angular.element('#overtimeRequestExplanation').focus()
			}, 1000)
		}

		function approve() {
			var otr = vm.otr.cloneFields()
			otr.status = OvertimeRequest.meta.statuses.approved
			return OvertimeRequest.update(otr.id, otr)
				.then(function (otr) {
					return OvertimeRequest.inject(otr)
				})
				.finally(exit)
		}

		function reject() {
			var otr = vm.otr.cloneFields()
			otr.status = OvertimeRequest.meta.statuses.rejected
			return OvertimeRequest.update(otr.id, otr)
				.then(function (otr) {
					return OvertimeRequest.inject(otr)
				})
				.finally(exit)
		}

		function exit() {
			$state.go('^')
		}
	}
}
